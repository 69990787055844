import React, { useContext, useState } from "react";
import { useQueryClient } from "react-query";
import { Link } from "react-router-dom";
import EekChart from "./EekChart";
import SleepChart from "./SleepChart";

import { MainContext } from "../../common/MainContext";

import { CheckboxLabel } from "./Labels";

const QuestionaireChart = () => {
  const [selectedDataset, setSelectedDataset] = useState("EEK");
  const [selectLink, setSelectLink] = useState({
    link1: "selected_link",
    link2: "select_link",
  });

  const [checkboxState, setCheckboxState] = useState({
    depression: true,
    anxiety: true,
    panic: true,
    social: true,
    asthenia: true,
    sleep: true,
  });
  const queryClient = useQueryClient();
  const { patientID, role } = useContext(MainContext);
  const year = new Date().getFullYear();
  const [activeYearIndex, setActiveYearIndex] = useState(year);

  const nextYear = activeYearIndex < year ? activeYearIndex + 1 : null;
  const prevYear = activeYearIndex > 2018 ? activeYearIndex - 1 : null;

  const prevYearLink = prevYear ? "<<  " : "None";
  const nextYearLink = nextYear ? "  >>" : "None";

  const handleCheckboxChange = (event) => {
    const { name, checked } = event.target;
    setCheckboxState((prevState) => ({ ...prevState, [name]: checked }));
  };

  const handleYearClick = (value) => {
    queryClient.invalidateQueries(
      `questionaiere1-${patientID}-${activeYearIndex}`
    );
    setActiveYearIndex(activeYearIndex + value);
  };

  const checkboxTranslations = CheckboxLabel();

  const onClickEvent = (link) => {
    if (link === "link1") {
      setSelectLink({
        link1: "selected_link",
        link2: "select_link",
      });
      setSelectedDataset("EEK");
    }
    if (link === "link2") {
      setSelectLink({
        link1: "select_link",
        link2: "selected_link",
      });
      setSelectedDataset("sleep");
    }
  };

  return (
    <div className="graph">
      {role === "medic" && (
        <>
          <Link
            className={selectLink.link1}
            onClick={() => onClickEvent("link1")}
          >
            {" "}
            Emotsionaalse enesetunde küsimustik{" "}
          </Link>
          <Link
            className={selectLink.link2}
            onClick={() => onClickEvent("link2")}
          >
            {" "}
            Une uuring{" "}
          </Link>
        </>
      )}
      <div className="chart">
        {selectedDataset === "EEK" ? (
          <EekChart queryYear={activeYearIndex} checkboxState={checkboxState} />
        ) : (
          <SleepChart queryYear={activeYearIndex} />
        )}
      </div>{" "}
      {selectedDataset === "EEK" && (
        <div className="checkboxes">
          {Object.keys(checkboxState).map((key) => (
            <div key={key}>
              <input
                type="checkbox"
                id={key}
                name={key}
                checked={checkboxState[key]}
                onChange={handleCheckboxChange}
              />
              <label htmlFor={key}>{checkboxTranslations[key] || key}</label>
            </div>
          ))}
        </div>
      )}
      <div className="select-links">
        {prevYear && (
          <Link className="select_link" onClick={() => handleYearClick(-1)}>
            {prevYearLink}
          </Link>
        )}

        <span className="select_link">{activeYearIndex}</span>
        {nextYear && (
          <Link className="select_link" onClick={() => handleYearClick(+1)}>
            {nextYearLink}
          </Link>
        )}
      </div>
    </div>
  );
};

export default QuestionaireChart;

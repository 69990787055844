import React, { useState } from "react";

import { useApiMutation } from "../../common/fetch/DataFetch";
import { Link, useNavigate } from "react-router-dom";

import Profile from "../../common/views/AddTreatments/Profile";
import ProfileDetail from "../../common/views/AddTreatments/ProfileDetail";
import ProfileDiagnosis from "../../common/views/AddTreatments/ProfileDiagnosis";
import AddTreatmentTrPlan from "./AddTreatmentTrPlan";

const AddTreatment = () => {
  const navigate = useNavigate();
  const [success, setSuccess] = useState(null);
  const [error, setError] = useState(null);
  const addTreatmentMutation = useApiMutation("POST", "/web/treatment/", {
    onSuccess: () => {
      setSuccess("Patsiendi andmed on edukalt lisatud");
    },
    onError: (error) => {
      return <div className="error">Something went wrong..., {error} </div>;
    },
  });
  const [date, setDate] = useState({
    dobDay: "",
    dobMonth: "",
    dobYear: "",
  });
  const [profile, setProfile] = useState({
    email: "",
    first_name: "",
    last_name: "",
    phone_number: "",
    dob: "",
    gender: "",
    social_security: "",
    address: "",
    city: "",
    language: "",
    insured: "True",
    work_place: "",
    work_position: "",
    family_status: "",
    religion: "",
    bio: "",
    expectations: "",
    previous_exp: "",
    interests: "",
  });
  const [details, setDetails] = useState({
    medical_history: "",
    chronic_diseases: "",
    weight: 0,
    height: 0,
    allergies: "",
    healthy_habits: "",
    unhealthy_habits: "",
    healthy_diet: "",
    general_health: "",
    medications: "",
    family_history: "",
  });
  const [diagnosis, setDiagnosis] = useState({
    diagnosis: "",
    anamnesis: "",
  });

  const [treatmentPlan, setTreatmentPlan] = useState([]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (profile.email === "") {
      alert("Palun sisesta patsiendi e-posti aadress");
      return;
    }
    if (profile.first_name === "") {
      alert("Palun sisesta patsiendi eesnimi");
      return;
    }
    if (profile.last_name === "") {
      alert("Palun sisesta patsiendi perekonnanimi");
      return;
    }
    if (profile.last_name === "") {
      alert("Palun sisesta patsiendi perekonnanimi");
      return;
    }
    if (date.dobDay === "" || date.dobMonth === "" || date.dobYear === "") {
      alert("Palun sisesta patsiendi sünnikuu, -päev ja -aasta");
      return;
    }
    let dob = `${date.dobYear}-${date.dobMonth}-${date.dobDay}`;
    let data = {
      ...profile,
      ...details,
      ...diagnosis,
      treatment: treatmentPlan, // Assign treatmentPlan array to the 'treatment_plan' key
      dob: dob,
    };

    try {
      const response = await addTreatmentMutation.mutateAsync(data);
      if (response) {
        setSuccess(response.message);
        setProfile({
          email: "",
          first_name: "",
          last_name: "",
          phone_number: "",
          dob: "",
          gender: "",
          social_security: "",
          address: "",
          city: "",
          language: "",
          insured: "True",
          work_place: "",
          work_position: "",
          family_status: "",
          religion: "",
          bio: "",
          expectations: "",
          previous_exp: "",
          interests: "",
        });
        setDetails({
          medical_history: "",
          chronic_diseases: "",
          weight: "",
          height: "",
          allergies: "",
          healthy_habits: "",
          unhealthy_habits: "",
          healthy_diet: "",
          general_health: "",
          medications: "",
          family_history: "",
        });
        setDiagnosis({
          diagnosis: "",
          anamnesis: "",
        });
        setTreatmentPlan([]);
      }
    } catch (error) {
      setError(error);
    }
  };

  return (
    <div className="container">
      <div className="sidepanel">
        <Profile
          profile={profile}
          setProfile={setProfile}
          date={date}
          setDate={setDate}
        />
      </div>
      <div className="main">
        <ProfileDiagnosis diagnosis={diagnosis} setDiagnosis={setDiagnosis} />
        <AddTreatmentTrPlan
          treatmentPlan={treatmentPlan}
          setTreatmentPlan={setTreatmentPlan}
        />
        <ProfileDetail details={details} setDetails={setDetails} />
        <div className="button_container_right">
          <Link onClick={() => navigate(-1)} className="button_inverted">
            Tagasi
          </Link>
          <button type="submit" onClick={handleSubmit}>
            Lisa ravi
          </button>
        </div>
        <div className="notification_right">
          {success && <div className="success">{success}</div>}
          {error && <div className="error">{error.message}</div>}
        </div>
      </div>
    </div>
  );
};

export default AddTreatment;

// /web/treatment/

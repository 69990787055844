import React from "react";
import { useEffect, useState } from "react";
import { useApi } from "../../common/fetch/DataFetch";
import { dateTimeDate } from "../../common/DateTime";
import Delete from "./Delete";

const MedicList = () => {
  const [medic, setMedic] = useState([]);
  const url = `/web/manage/medics-list`;
  const key = `MedicList`;
  const medicList = useApi(key, url);
  const [success, setSuccess] = useState(null);
  const [error, setError] = useState(null);

  useEffect(() => {
    if (medicList.data) {
      setMedic(medicList.data.medics);
    }
  }, [medicList]);

  return (
    <div className="main_form admin">
      <table>
        <thead>
          <tr>
            <th>First Name</th>
            <th>Last Name</th>
            <th>e-mail</th>
            <th>Phone</th>
            <th>Institution</th>
            <th>Title</th>
            <th>Date added</th>
            <th>Delete</th>
          </tr>
        </thead>
        <tbody>
          {medic.map((medic, key) => (
            <tr key={key}>
              <td>{medic.first_name}</td>
              <td>{medic.last_name}</td>
              <td>{medic.email}</td>
              <td>{medic.phone_number}</td>
              <td>{medic.institution}</td>
              <td>{medic.title}</td>
              <td>{dateTimeDate(medic.date_added)}</td>
              <td>
                {
                  <Delete
                    userID={medic.user_id}
                    setSuccess={setSuccess}
                    setError={setError}
                    email={null}
                  />
                }
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      <div className="success">{success}</div>
      <div className="error">{error}</div>
    </div>
  );
};

export default MedicList;

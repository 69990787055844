import React from "react";
import { Link } from "react-router-dom";

import { dateTimeDate } from "../../common/DateTime";

const PatientSearchResults = ({ searchResults, setQuery }) => {
  return (
    <>
      <ul className="search_results">
        {searchResults.map(
          ({ user_id, first_name, last_name, treatments }, key) => (
            <li key={key}>
              <Link onClick={() => setQuery("")} to={"patients/" + user_id}>
                {first_name} {last_name}
              </Link>
              {treatments.map(
                ({ treatment_id, medic_name, medic_lastname, date_added }) => (
                  <ul key={treatment_id}>
                    <li>
                      <Link
                        className="green_link"
                        onClick={() => setQuery("")}
                        to={
                          "patients/" + user_id + "/treatments/" + treatment_id
                        }
                      >
                        {" "}
                        Lisas: {medic_name} {medic_lastname}{" "}
                        {dateTimeDate(date_added)}
                      </Link>
                    </li>
                  </ul>
                )
              )}
            </li>
          )
        )}
      </ul>
    </>
  );
};

export default PatientSearchResults;

import React, { useState } from "react";
import { useApiMutation } from "../fetch/DataFetch";

import { MainContext } from "../MainContext";
import { validateEmail, validatePassword } from "../Validators";

const Login = () => {
  const { setUserID, setJwt, setRole } = React.useContext(MainContext);
  const [error, setError] = useState(null);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const errorStyle = { display: error ? "block" : "none", color: "red" };
  const loginMutation = useApiMutation(
    "POST",
    "/web/user/login",
    {
      onError: (error) => {
        setError(error);
      },
    },
    true, // useFormData (false because we are not sending a form)
    false
  );

  const validateForm = () => {
    return validateEmail(email) && validatePassword(password);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const formData = new FormData();
    formData.append("username", email);
    formData.append("password", password);
    try {
      const response = await loginMutation.mutateAsync(formData);
      if (response.access_token && response.user_id && response.role) {
        localStorage.setItem("token", response.access_token);
        localStorage.setItem("userID", response.user_id);
        localStorage.setItem("role", response.role);
        setUserID(response.user_id);
        setJwt(response.access_token);
        setRole(response.role);
      } else {
        setError("Login failed, please try again.");
      }
    } catch (err) {
      setError("Login failed, please try again.");
    }
  };

  return (
    <div className="login_form">
      <div style={errorStyle}>{error || loginMutation.error?.message}</div>
      <h1>Tere tulemast</h1>
      <form onSubmit={handleSubmit}>
        <div>
          <label htmlFor="email">e-mail</label>
          <input
            className="line_input"
            placeholder="epost@epost.ee"
            type="email"
            name="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
        </div>
        <div>
          <label htmlFor="password">Password</label>
          <input
            className="line_input"
            placeholder="********"
            type="password"
            name="password"
            value={password}
            autoComplete="current-password"
            onChange={(e) => setPassword(e.target.value)}
          />
        </div>
        <button type="submit" disabled={!validateForm()}>
          Login
        </button>
      </form>
    </div>
  );
};

export default Login;

// Path: src/common/Logout.js

import React, { useContext, useState } from "react";
import { Link } from "react-router-dom";
import { useQueryClient } from "react-query";

import { MainContext } from "../../common/MainContext";
import { useApiMutation } from "../../common/fetch/DataFetch";

const MedicSearchResults = ({ searchResults, setQuery }) => {
  const queryClient = useQueryClient();
  const { trID } = useContext(MainContext);
  const [medic, setMedic] = useState("");
  const [message, setMessage] = useState("");
  const addTeamMutation = useApiMutation(
    "POST",
    "/web/treatment/treatment-team/"
  );

  const handleSubmit = async (e) => {
    try {
      if (medic) {
        const data = {
          treatment_id: trID,
          medic_email: medic,
        };
        await addTeamMutation.mutateAsync(data);
        queryClient.invalidateQueries(`treatmentTeam-${trID}`);
        setMessage("Uus meeskonnaliige lisatud! Patsient peab kinnitama.");
        setTimeout(() => setMessage(""), 10000);
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <>
      <ul className="medic_search_results">
        {searchResults.map(({ first_name, last_name, email }, key) => (
          <li key={key}>
            <Link
              onClick={() => {
                if (
                  window.confirm(
                    "Oled kindel, et sooovid lisada " +
                      first_name +
                      " " +
                      last_name +
                      " ravimeeskonda?"
                  )
                ) {
                  setQuery("");
                  setMedic(email);
                  handleSubmit();
                }
              }}
            >
              {first_name} {last_name} {email}
            </Link>
          </li>
        ))}
      </ul>
      <div className="notification_right">
        {message && <div className="success">{message}</div>}
      </div>
    </>
  );
};
export default MedicSearchResults;

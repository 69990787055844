import React from "react";
import { Route, Routes, Link } from "react-router-dom";

import InviteUser from "./InviteUser";
import MedicList from "./ListMedics";
import AdminList from "./ListAdmins";
import DeleteUser from "./DeleteUser";
import LogOut from "../../common/auth/LogOut";
import ActivityLogs from "./ActivityLogs";
import PassChange from "./PassChange";

export default function AdminHome() {
  return (
    <div>
      <div className="header">
        <div className="header_left_menu">
          <span>
            <Link to="/admin/add-medic/">Add medic or admin</Link>
          </span>
          <span>
            <Link to="/admin/medic-list/">List of medics</Link>
          </span>
          <span>
            <Link to="/admin/admin-list/">List of admins</Link>
          </span>
          <span>
            <Link to="/admin/user-delete/">Delete User</Link>
          </span>
          <span>
            <Link to="/admin/pass-change">Change password</Link>
          </span>
          <span>
            <Link to="/admin/activity-logs/">Logs</Link>
          </span>
        </div>
        <div className="current_date"></div>
        <div className="header_right_menu">
          <span>
            <LogOut />
          </span>
        </div>
      </div>

      <div>
        <Routes>
          <Route path="/medic-list" element={<MedicList />} />
          <Route path="/add-medic" element={<InviteUser />} />
          <Route path="/admin-list" element={<AdminList />} />
          <Route path="/user-delete" element={<DeleteUser />} />
          <Route path="/activity-logs" element={<ActivityLogs />} />
          <Route path="/pass-change" element={<PassChange />} />
        </Routes>
      </div>
    </div>
  );
}

//GET TREATMENT COMMENTS

import React from "react";
import { dateTimeDate } from "../DateTime";
import TreatmentCommentDelete from "../../modules/medic/TreatmentCommentDelete";

const TreatmentComments = ({ comments }) => {
  return (
    <>
      {comments.map(
        (
          {
            c_date_added,
            comment,
            c_first_name,
            c_last_name,
            treatment_comment_id,
            added_bu_user_id,
          },
          key
        ) => (
          <div key={key} className="patient_treatment">
            <div className="patient_treatment_date">
              {dateTimeDate(c_date_added)}
            </div>
            <div className="patient_treatment_medic">
              Lisas: {c_first_name} {c_last_name}
            </div>
            <div className="patient_treatment_diagnosis">{comment}</div>
            <TreatmentCommentDelete
              TreatmentCommentID={treatment_comment_id}
              CommentatorID={added_bu_user_id}
            />
          </div>
        )
      )}
    </>
  );
};

export default TreatmentComments;

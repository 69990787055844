import React, { useState, useContext } from "react";
import { useQueryClient } from "react-query";

import { useApiMutation } from "../../common/fetch/DataFetch";

import { MainContext } from "../../common/MainContext";

const ToggleTreatmentTeam = ({ consent, queryKey, medic_id }) => {
  const queryClient = useQueryClient();
  const url = `/web/treatment/treatment-team/`;
  const { trID } = useContext(MainContext);
  const [isConsentGiven, setIsConsentGiven] = useState(consent);
  const [formdata, setFormData] = useState({
    consent: isConsentGiven,
    treatment_id: trID,
    medic_id: medic_id,
  });
  const toggleConsentMutation = useApiMutation("PUT", url);

  const handleOnClick = async () => {
    const newConsent = !isConsentGiven;
    setIsConsentGiven(newConsent);
    const updatedFormData = { ...formdata, consent: newConsent };
    setFormData(updatedFormData);

    try {
      const response = await toggleConsentMutation.mutateAsync(updatedFormData);
      if (response) {
        queryClient.invalidateQueries(queryKey);
      }
    } catch (error) {
      setIsConsentGiven(!newConsent);
    }
  };

  const buttonClassName = isConsentGiven ? "show_button" : "hide_button";

  return <button className={buttonClassName} onClick={handleOnClick} />;
};

export default ToggleTreatmentTeam;

import React, { useContext, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { MainContext } from "../MainContext";
import { useApiMutation } from "../fetch/DataFetch";

import Profile from "./AddTreatments/Profile";
import ProfileDetail from "./AddTreatments/ProfileDetail";
import Expectations from "./AddTreatments/Expectations";

const EditPatient = () => {
  const navigate = useNavigate();
  const { role, patientData, patientID } = useContext(MainContext);
  const [success, setSuccess] = useState(null);
  const [error, setError] = useState(null);
  const [year, month, day] = patientData.dob.split("-");

  const [date, setDate] = useState({
    dobDay: day,
    dobMonth: month,
    dobYear: year,
  });

  const [profile, setProfile] = useState({
    first_name: patientData.first_name || "",
    last_name: patientData.last_name || "",
    email: patientData.email || "",
    phone_number: patientData.phone_number || "",
    gender: patientData.gender.toLowerCase() || "",
    social_security: patientData.social_security || "",
    address: patientData.address || "",
    city: patientData.city || "",
    language: patientData.language || "",
    insured: patientData.insured || "",
    work_place: patientData.work_place || "",
    work_position: patientData.work_position || "",
    family_status: patientData.family_status || "",
    religion: patientData.religion || "",
  });

  const [details, setDetails] = useState({
    medical_history: patientData.medical_history || "",
    chronic_diseases: patientData.chronic_diseases || "",
    weight: patientData.weight || 0,
    height: patientData.height || 0,
    allergies: patientData.allergies || "",
    healthy_habits: patientData.healthy_habits || "",
    unhealthy_habits: patientData.unhealthy_habits || "",
    healthy_diet: patientData.healthy_diet || "",
    general_health: patientData.general_health || "",
    medications: patientData.medications || "",
    family_history: patientData.family_history || "",
  });

  const [expectations, setExpectations] = useState({
    bio: patientData.bio || "",
    expectations: patientData.expectations || "",
    previous_exp: patientData.previous_exp || "",
    interests: patientData.interests || "",
  });

  const editPatientMutation = useApiMutation(
    "PUT",
    "/web/user/patient-detail",
    {
      onSuccess: () => {
        setSuccess("Patsiendi andmed on edukalt täiendatud");
      },
      onError: (error) => {
        return <div className="error">Something went wrong..., {error} </div>;
      },
    }
  );

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (profile.email === "") {
      alert("Palun sisesta patsiendi e-posti aadress");
      return;
    }
    if (profile.first_name === "") {
      alert("Palun sisesta patsiendi eesnimi");
      return;
    }
    if (profile.last_name === "") {
      alert("Palun sisesta patsiendi perekonnanimi");
      return;
    }
    if (profile.last_name === "") {
      alert("Palun sisesta patsiendi perekonnanimi");
      return;
    }
    if (date.dobDay === "" || date.dobMonth === "" || date.dobYear === "") {
      alert("Palun sisesta patsiendi sünnikuu, -päev ja -aasta");
      return;
    }

    if (details.height % 1 !== 0 || details.weight % 1 !== 0) {
      alert("Pikkus ja kaal peavad olema täisarvud");
      return;
    }

    if (details.social_security === "") {
      alert("Isikukood on kohustuslik väli");
      return;
    }

    let dob = `${date.dobYear}-${date.dobMonth}-${date.dobDay}`;
    let data = {
      ...profile,
      ...details,
      ...expectations,
      dob: dob,
      patient_id: patientID,
      role: role,
    };

    try {
      const response = await editPatientMutation.mutateAsync(data);
      if (response) {
        setSuccess(response.message);
      }
    } catch (error) {
      setError(error.message);
    }
  };

  return (
    <div className="container">
      <div className="sidepanel">
        <Profile
          profile={profile}
          setProfile={setProfile}
          date={date}
          setDate={setDate}
        />
      </div>
      <div className="main">
        <ProfileDetail details={details} setDetails={setDetails} />
        <Expectations
          expectations={expectations}
          setExpectations={setExpectations}
        />

        <div className="button_container_right">
          <Link onClick={() => navigate(-1)} className="button_inverted">
            Tagasi
          </Link>
          <button type="submit" onClick={handleSubmit}>
            Salvesta
          </button>
        </div>
        <div className="notification_right">
          {success && <div className="success">{success}</div>}
          {error && <div className="error">{error}</div>}
        </div>
      </div>
    </div>
  );
};

export default EditPatient;

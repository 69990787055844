import React from "react";
import MedicSearch from "./MedicSearch";

const AddTreatmentTeam = () => {
  return (
    <div className="mobile_hide_wrapper">
      <MedicSearch />
    </div>
  );
};

export default AddTreatmentTeam;

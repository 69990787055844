import React from "react";
import TreatmentDetail from "../../common/views/TreatmentDetail";
import TreatmentComment from "../../common/views/TreatmentComment";

const TreatmentContent = () => {
  return (
    <>
      <TreatmentDetail />
      <TreatmentComment />
    </>
  );
};

export default TreatmentContent;

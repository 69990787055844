import React, { useState } from "react";
import Delete from "./Delete";

const DeleteUser = () => {
  const [success, setSuccess] = useState(null);
  const [error, setError] = useState(null);
  const handleChange = (e) => {
    setDel(e.target.value);
  };

  const [del, setDel] = useState("");

  return (
    <div className="sidepanel">
      <h4>Sisesta kasutaja email</h4>
      <div className="sidepanel_item">
        <input
          type="email"
          name="email"
          id="email"
          value={del.email}
          onChange={handleChange}
        />

        <Delete
          userID={null}
          setSuccess={setSuccess}
          setError={setError}
          email={del}
        />
        <div className="success">{success}</div>
        <div className="error">{error}</div>
      </div>
    </div>
  );
};

export default DeleteUser;

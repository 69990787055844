import React, { useEffect, useState } from "react";
import { useApi } from "../../common/fetch/DataFetch";
import { dateTimeDate } from "../../common/DateTime";
import { Link, useNavigate } from "react-router-dom";

const PatientList = () => {
  const navigate = useNavigate();
  const [patientList, setPatientList] = useState([]);
  const url = `/web/search/patient_list/`;
  const key = `PatientList`;
  const patientListQuery = useApi(key, url);

  useEffect(() => {
    if (patientListQuery && patientListQuery.data) {
      setPatientList(patientListQuery.data.patients);
    }
  }, [patientListQuery, setPatientList]);

  if (patientListQuery.isLoading) {
    return <div className="load_spinner"></div>;
  }

  if (patientListQuery.isError) {
    return <div className="error">Error</div>;
  }

  if (patientList.length > 0) {
    return (
      <div className="container">
        <div className="sidepanel">
          <h4>Minu Patsiendid</h4>
          <div className="sidepanel_item patients">
            {patientList.map(
              ({ user_id, first_name, last_name, treatments }) => (
                <div className="text_block" key={user_id}>
                  <li>
                    <Link to={"/medic/patients/" + user_id}>
                      {first_name} {last_name}
                    </Link>
                    {treatments.map(
                      ({
                        treatment_id,
                        medic_name,
                        medic_lastname,
                        date_added,
                      }) => (
                        <ul key={treatment_id}>
                          <li>
                            <Link
                              className="green_link"
                              to={
                                "/medic/patients/" +
                                user_id +
                                "/treatments/" +
                                treatment_id
                              }
                            >
                              Lisas: {medic_name} {medic_lastname}{" "}
                              {dateTimeDate(date_added)}
                            </Link>
                          </li>
                        </ul>
                      )
                    )}
                  </li>
                </div>
              )
            )}
          </div>
          <div className="button_container_right">
            <button
              className="button_inverted"
              type="submit"
              onClick={() => navigate(-1)}
            >
              Tagasi
            </button>
          </div>
        </div>
      </div>
    );
  }

  return <div className="load_spinner"></div>;
};

export default PatientList;

import React, { useContext, useEffect } from "react";

import { MainContext } from "../../common/MainContext";

import { useApi } from "../../common/fetch/DataFetch";

import PatientGeneral from "../../common/views/PatientGeneral";
import PatientDetail from "../../common/views/PatientDetail";
import PatientMedical from "../../common/views/PatientMedical";
import ToggleTreatments from "./ToggleTreatments";

const PatientProfile = () => {
  const { role, setPatientData, setPatientID, patientData, patientID, userID } =
    useContext(MainContext);
  const url = `/web/user/patient-detail?role=${role}&patient_id=${userID}`;
  const key = `patientData-${userID}`;
  const patientResponse = useApi(key, url);

  useEffect(() => {
    if (patientResponse.data) {
      setPatientData(patientResponse.data);
      setPatientID(userID);
    }
  }, [patientResponse, setPatientData, setPatientID, userID]);

  if (patientResponse.isLoading) {
    return <div className="load_spinner"></div>;
  }

  if (patientResponse.isError) {
    return <div className="error">Something went wrong</div>;
  }

  if (patientData && patientID) {
    return (
      <div className="container">
        <div className="sidepanel">
          <PatientGeneral />
          <PatientMedical />
          <ToggleTreatments />
        </div>
        <div className="main">
          <PatientDetail />
        </div>
      </div>
    );
  } else {
    return <div className="load_spinner"></div>;
  }
};

export default PatientProfile;

import React, { useState, useEffect } from "react";
import { useApiMutation } from "../../common/fetch/DataFetch";
import { useParams, useNavigate } from "react-router-dom";
import { MainContext } from "../../common/MainContext";
import { validateEmail, validatePassword } from "../../common/Validators";

export default function MedicAccept({ url }) {
  const { inviteID } = useParams();
  const { role, setRole, setUserID, setJwt } = React.useContext(MainContext);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [password1, setPassword1] = useState("");
  const [error, setError] = useState(null);
  const navigate = useNavigate();

  const registerMutation = useApiMutation(
    "POST",
    url,
    {
      onError: setError,
    },
    false,
    false
  );

  const errorStyle = { display: error ? "block" : "none", color: "red" };

  const isFormValid = () =>
    password === password1 &&
    validateEmail(email) &&
    validatePassword(password);

  const handleAccept = async (event) => {
    setJwt(null);
    localStorage.clear();
    event.preventDefault();
    const form = event.currentTarget;
    form.disabled = true;

    const body = {
      email,
      password,
      invitation_code: inviteID,
    };

    try {
      const response = await registerMutation.mutateAsync(body);
      if (response?.access_token) {
        localStorage.setItem("token", response.access_token);
        localStorage.setItem("userID", response.user_id);
        localStorage.setItem("role", response.role);
        setUserID(response.user_id);
        setJwt(response.access_token);
        setRole(response.role);
        return;
      }
      setError(
        "Viga! Proovi uuesti või võta meiega ühendust help@therasyng.eu."
      );
    } catch (err) {
      setError(
        "Viga! Proovi uuesti või võta meiega ühendust help@therasyng.eu."
      );
    }
    form.disabled = true;
  };

  useEffect(() => {
    if (role) {
      navigate(`/${role}`);
    }
  }, [role, navigate]);

  return (
    <div className="login_form">
      <h1>Teretulemast!</h1>
      <div style={errorStyle}>{error}</div>
      <form onSubmit={handleAccept}>
        <div>
          <label htmlFor="email">e-mail</label>
          <input
            className="line_input"
            placeholder="epost@epost.ee"
            type="email"
            name="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
        </div>
        <div>
          <label htmlFor="password">Password</label>
          <input
            className="line_input"
            placeholder="********"
            type="password"
            name="password"
            value={password}
            autoComplete="current-password"
            onChange={(e) => setPassword(e.target.value)}
          />
        </div>
        <div>
          <label htmlFor="password">Password again</label>
          <input
            className="line_input"
            placeholder="********"
            type="password"
            name="password1"
            value={password1}
            autoComplete="current-password"
            onChange={(e) => setPassword1(e.target.value)}
          />
        </div>
        <button type="submit" disabled={!isFormValid()}>
          Edasi
        </button>
      </form>
    </div>
  );
}

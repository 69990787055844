import React, { useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";

import { MainContext } from "../../common/MainContext";
import { useApi } from "../../common/fetch/DataFetch";

import { dateTimeDate } from "../../common/DateTime";

const TreatmentList = () => {
  const { patientID } = useContext(MainContext);
  const [treatmentList, setTreatmentList] = useState(null);
  const url = `/web/treatment/?patient_id=${patientID}`;
  const key = `patientTreatments-${patientID}`;
  const treatmentListResp = useApi(key, url);

  useEffect(() => {
    if (treatmentListResp.data) {
      setTreatmentList(treatmentListResp.data);
    }
  }, [treatmentListResp, setTreatmentList]);

  if (treatmentListResp.isLoading) {
    return <div className="load_spinner"></div>;
  }

  if (treatmentListResp.isError) {
    return <div className="error">Something went wrong</div>;
  }

  if (treatmentList) {
    return (
      <>
        <p className="side_panel_heading">Patsiendi ravid</p>
        <div className="sidepanel_item">
          {treatmentList.treatment_list.map(
            ({ date_added, treatment_id }, key) => (
              <div key={key}>
                <div>
                  <Link to={`treatments/${treatment_id}`}>
                    {dateTimeDate(date_added)}{" "}
                  </Link>
                </div>
              </div>
            )
          )}
        </div>
      </>
    );
  } else {
    return <div className="load_spinner"></div>;
  }
};

export default TreatmentList;

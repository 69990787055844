import React from "react";

import RefreshToken from "./RefreshToken";
import { MainContext } from "../MainContext";

export default function Auth() {
  const { jwt } = React.useContext(MainContext);
  if (!jwt) {
    localStorage.clear();
  } else {
    localStorage.setItem("token", jwt);
  }
  return (
    <React.Fragment>
      <RefreshToken />
    </React.Fragment>
  );
}

import React, { useState } from "react";
import { useApiMutation } from "../../common/fetch/DataFetch";

const PassChange = () => {
  const [success, setSuccess] = useState(null);
  const [error, setError] = useState(null);
  const [passwordsMatch, setPasswordsMatch] = useState(false);
  const [newPassword2, setNewPassword2] = useState("");
  const [formdata, setFormData] = useState({
    email: "",
    old_password: "",
    new_password: "",
  });

  const pswMutation = useApiMutation("PUT", "/web/user/credentials", {
    onSuccess: () => {
      setSuccess("Parool muudetud");
      setTimeout(() => setSuccess(""), 3000);
    },
    onError: (error) => {
      setError("Something went wrong...");
      setTimeout(() => setError(""), 3000);
    },
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    if (name === "new_password2") {
      setNewPassword2(value);
    } else {
      setFormData({
        ...formdata,
        [name]: value,
      });
    }
  };

  const validatePasswordMatch = () => {
    if (formdata.new_password === newPassword2) {
      setPasswordsMatch(true);
    } else {
      setPasswordsMatch(false);
    }
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    const form = event.currentTarget;
    form.disabled = true;

    try {
      await pswMutation.mutateAsync(formdata);

      setSuccess("Parool muudetud");
      setTimeout(() => setSuccess(""), 3000);
    } catch (error) {
      setError("Something went wrong...");
      setTimeout(() => setError(""), 3000);
    }
    form.disabled = false;
  };

  return (
    <div className="sidepanel">
      <h4>Sisesta kasutaja email</h4>
      <div className="sidepanel_item">
        <form onSubmit={handleSubmit}>
          <label htmlFor="email">e-mail</label>
          <input
            type="email"
            name="email"
            id="email"
            value={formdata.email}
            onChange={handleChange}
          />
          <label htmlFor="email">Old Passsword</label>
          <input
            type="password"
            name="old_password"
            id="old_password"
            value={formdata.old_password}
            onChange={handleChange}
          />
          <label htmlFor="email">New Passsword</label>
          <input
            type="password"
            name="new_password"
            id="new_password"
            value={formdata.new_password}
            onChange={handleChange}
          />
          <label htmlFor="email">New Passsword again</label>
          <input
            type="password"
            name="new_password2"
            id="new_password2"
            value={formdata.new_password2}
            onChange={handleChange}
            onBlur={validatePasswordMatch} // Validate on blur
          />
          <button type="submit" disabled={!passwordsMatch}>
            Muuda parool
          </button>
        </form>
        <div className="success">{success}</div>
        <div className="error">{error}</div>
      </div>
    </div>
  );
};

export default PassChange;

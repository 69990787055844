import React from "react";

const Expectations = ({ expectations, setExpectations }) => {
  const handleChange = (e) => {
    let { name, value } = e.target;
    setExpectations({
      ...expectations,
      [name]: value,
    });
  };
  return (
    <>
      <h4>Minust üldiselt</h4>
      <div className="main_form">
        <form>
          <label htmlFor="bio">Minust</label>
          <textarea
            rows="2"
            maxLength="10000"
            type="text"
            name="bio"
            value={expectations.bio}
            onChange={handleChange}
          />
          <label htmlFor="expectations">Mida vajan enda ravimeeskonnalt</label>
          <textarea
            rows="2"
            maxLength="1000"
            type="text"
            name="expectations"
            value={expectations.expectations}
            onChange={handleChange}
          />
          <label htmlFor="previous_exp">
            Minu eelnevad ravi ja/või teraapia kogemused
          </label>
          <textarea
            rows="2"
            maxLength="1000"
            type="text"
            name="previous_exp"
            value={expectations.previous_exp}
            onChange={handleChange}
          />
          <label htmlFor="interests">Minu huvid</label>
          <textarea
            rows="2"
            maxLength="1000"
            type="text"
            name="interests"
            value={expectations.interests}
            onChange={handleChange}
          />
        </form>
      </div>
    </>
  );
};

export default Expectations;

import React, { useContext, useEffect } from "react";

import { MainContext } from "../../common/MainContext";
import { Route, Routes, Link } from "react-router-dom";

import { useApi } from "../../common/fetch/DataFetch";

import PatientTreatment from "./PatientTreatment";
import PatientProfile from "./PatientProfile";
import LogOut from "../../common/auth/LogOut";
import EditPatient from "../../common/views/EditPatient";

import { DateString } from "../../common/DateTime";

export default function PatientHome() {
  const { userID, setPatientID, treatmentList, setTreatmentList } =
    useContext(MainContext);
  const url = `/web/treatment/?patient_id=${userID}`;
  const key = `TreatmentList-${userID}`;
  const treatmentListResp = useApi(key, url);

  useEffect(() => {
    setPatientID(userID);
    if (
      treatmentListResp.data &&
      treatmentListResp.data.treatment_list &&
      treatmentListResp.data.treatment_list.length > 0
    )
      setTreatmentList(treatmentListResp.data.treatment_list);
  }, [treatmentListResp, setTreatmentList, setPatientID, userID]);

  if (treatmentListResp.isLoading) {
    return <div className="load_spinner"></div>;
  }

  if (treatmentListResp.isError) {
    return <div className="error">Something went wrong</div>;
  }

  if (treatmentList.length > 0) {
    return (
      <>
        <div className="header">
          <div className="header_left_menu">
            <span>
              <Link to="/patient/profile/">Profiil</Link>
            </span>
          </div>
          <div className="current_date">{DateString()}</div>
          <div className="header_right_menu">
            <span>
              <LogOut />
            </span>
          </div>
        </div>

        <Routes>
          <Route path="/*" element={<PatientTreatment />} />
          <Route path="/profile" element={<PatientProfile />} />
          <Route path="/profile/edit" element={<EditPatient />} />
        </Routes>
      </>
    );
  } else {
    return <div className="load_spinner"></div>;
  }
}
//<ul className="header_left_menu">

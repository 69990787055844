import React from "react";
import { useEffect, useState } from "react";
import { useApi } from "../../common/fetch/DataFetch";
import { dateTimeDate } from "../../common/DateTime";
import Delete from "./Delete";

const AdminList = () => {
  const [admin, setAdmin] = useState([]);
  const url = `/web/manage/admins-list`;
  const key = `AdminList`;
  const adminList = useApi(key, url);
  const [success, setSuccess] = useState(null);
  const [error, setError] = useState(null);

  useEffect(() => {
    if (adminList.data) {
      setAdmin(adminList.data.admins);
    }
  }, [adminList]);

  return (
    <>
      <div className="main_form admin">
        <table>
          <thead>
            <tr>
              <th>First Name</th>
              <th>Last Name</th>
              <th>e-mail</th>
              <th>Phone</th>
              <th>Date added</th>

              <th>Delete</th>
            </tr>
          </thead>
          <tbody>
            {admin.map((admin, key) => (
              <tr key={key}>
                <td>{admin.first_name}</td>
                <td>{admin.last_name}</td>
                <td>{admin.email}</td>
                <td>{admin.phone_number}</td>
                <td>{dateTimeDate(admin.date_added)}</td>

                <td>
                  <Delete
                    userID={admin.user_id}
                    setSuccess={setSuccess}
                    setError={setError}
                    email={null}
                  />
                </td>
              </tr>
            ))}
          </tbody>
        </table>
        <div className="success">{success}</div>
        <div className="error">{error}</div>
      </div>
    </>
  );
};

export default AdminList;

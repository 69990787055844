import React from "react";
import { Link } from "react-router-dom";

import { MainContext } from "../MainContext";

export default function LogOut() {
  const {
    setJwt,
    setPatientData,
    setTreatmentData,
    setTrID,
    setUserID,
    setRole,
    setPatientID,
    setViewState,
  } = React.useContext(MainContext);
  const logout = () => {
    setJwt(null);
    setTrID(null);
    setPatientData(null);
    setTreatmentData(null);
    setUserID(null);
    setRole(null);
    setPatientID(null);
    localStorage.clear();
    setViewState("treatment");
  };
  // link home use LINK

  return (
    <Link to="/" onClick={logout}>
      Välju
    </Link>
  );
}

// Path: src/common/RefreshToken.js

import React from "react";
import { Line } from "react-chartjs-2";
import Chart from "chart.js/auto";

import { Labels } from "./Labels";

Chart.defaults.font.family = "'Nunito Sans', sans-serif";

const SleepChart = ({ queryYear }) => {
  const d = {
    // 2017: { Uni: [0, 0, 0, 0, 0, 8, 7, 8, 9, 11, 11, 5] },
    // 2018: { Uni: [8, 7, 8, 7, 8, 8, 7, 8, 8, 7, 9, 8] },
    // 2019: { Uni: [0, 0, 0, 0, 0, 8, 7, 8, 9, 11, 11, 5] },
    // 2020: { Uni: [6, 6, 4, 5, 4, 6, 7, 8, 8, 9, 8, 8] },
    // 2021: { Uni: [6, 6, 7, 8, 0, 0, 0, 0, 7, 7, 8, 7] },
    // 2022: { Uni: [8, 7, 8, 7, 9, 9, 9, 8, 7, 8, 6, 7] },
    // 2023: { Uni: [7, 6, 5, 5] },
  };

  const sleep_options = {
    maintainAspectRatio: false,
    plugins: {
      legend: {
        display: false,
      },
    },
    scales: {
      y: {
        display: true,
        grid: {
          display: true,
        },
        ticks: {
          beginAtZero: true,
          stepSize: 1,
          grace: "5%",
        },
      },
      x: {
        display: true,
        grid: {
          display: true,
        },
      },
    },
  };

  const sleep_data = {
    labels: Labels(),
    datasets: [
      {
        data: d[queryYear]?.Uni,
        fill: false,
        borderColor: "#e8c048",
      },
    ],
  };

  return <Line data={sleep_data} options={sleep_options} />;
};
export default SleepChart;

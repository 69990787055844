import React, { useState } from "react";

import { useApiMutation } from "../../common/fetch/DataFetch";

const InviteUser = () => {
  const [success, setSuccess] = useState("");
  const [error, setError] = useState("");
  const [formdata, setFormData] = useState({
    email: "",
    first_name: "",
    last_name: "",
    role: "medic",
    institution: "",
    title: "",
  });

  const InviteMutation = useApiMutation("POST", "/web/manage/add-admin-medic", {
    onSuccess: () => {
      setSuccess("Kasutja kutse saadetud");
      setTimeout(() => setSuccess(""), 3000);
    },
    onError: (error) => {
      setError("Something went wrong...");
      setTimeout(() => setError(""), 3000);
    },
  });

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    const newValue =
      type === "checkbox" ? (checked ? "admin" : "medic") : value;

    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: newValue,
    }));
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    const form = event.currentTarget;
    form.disabled = true;

    try {
      await InviteMutation.mutateAsync(formdata);

      setSuccess("Kasutja kutse saadetud");
      setTimeout(() => setSuccess(""), 3000);
    } catch (error) {
      setError("Something went wrong...");
      setTimeout(() => setError(""), 3000);
    }
    form.disabled = false;
  };

  return (
    <div className="sidepanel">
      <h4>Invite user</h4>
      <div className="sidepanel_item">
        <form onSubmit={handleSubmit}>
          <label htmlFor="email">e-mail</label>
          <input
            type="text"
            name="email"
            id="email"
            value={formdata.email}
            onChange={handleChange}
          />
          <label htmlFor="first_name">first name</label>
          <input
            type="text"
            name="first_name"
            value={formdata.first_name}
            onChange={handleChange}
          />
          <label htmlFor="last_name">last name</label>
          <input
            type="text"
            name="last_name"
            value={formdata.last_name}
            onChange={handleChange}
          />
          <label htmlFor="institution">institution</label>
          <input
            type="text"
            name="institution"
            id="institution"
            value={formdata.institution}
            onChange={handleChange}
          />
          <label htmlFor="title">title</label>
          <input
            type="text"
            name="title"
            id="title"
            value={formdata.title}
            onChange={handleChange}
          />
          <label htmlFor="role">new admin</label>
          <input
            type="checkbox"
            name="role"
            id="role"
            checked={formdata.role === "admin"}
            onChange={handleChange}
          />
          <button type="submit" className="Login-button">
            Add invite
          </button>
        </form>
        <div className="success">{success}</div>
        <div className="error">{error}</div>
      </div>
    </div>
  );
};

export default InviteUser;

import React from "react";
import Accept from "./Accept";

export function MedicAccept() {
  const url = "/invites/medic/accept";
  return <Accept url={url} />;
}

export function PatientAccept() {
  const url = "/invites/patient/accept";
  return <Accept url={url} />;
}

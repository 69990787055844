import React, { useContext } from "react";
import { useQueryClient } from "react-query";
import { Link } from "react-router-dom";
import { MainContext } from "../../common/MainContext";

import { dateTimeDate } from "../../common/DateTime";

const ToggleTreatments = ({ setTrPlan, newTrId, setTrID }) => {
  const queryClient = useQueryClient();
  const { treatmentList } = useContext(MainContext);

  // return treatment list in a table
  const onClickEvent = (treatment_id) => {
    queryClient.invalidateQueries(`treatmentData-${newTrId}`);
    setTrPlan(treatment_id);
    setTrID(treatment_id);
  };

  return (
    <>
      <p className="side_panel_heading">Minu ravid</p>
      <div className="sidepanel_item">
        {treatmentList.map(({ date_added, treatment_id }, key) => (
          <div key={key}>
            <Link
              className="green_link"
              onClick={() => onClickEvent(treatment_id)}
            >
              Lisatud {dateTimeDate(date_added)}
            </Link>
          </div>
        ))}
      </div>
    </>
  );
};

export default ToggleTreatments;

import React, { useContext, useEffect } from "react";
import { MainContext } from "../../common/MainContext";
import { useParams } from "react-router-dom";
import { useApi } from "../../common/fetch/DataFetch";

import TreatmentMain from "./TreatmentMain";

const Treatment = () => {
  const { user_id, treatmentID } = useParams();
  const {
    role,
    setPatientData,
    setPatientID,
    patientData,
    setTrID,
    trID,
    patientID,
  } = useContext(MainContext);

  const url = `/web/user/patient-detail?role=${role}&patient_id=${user_id}`;
  const key = `patientData-${user_id}`;
  const patientResponse = useApi(key, url);

  useEffect(() => {
    if (patientResponse.data) {
      setPatientData(patientResponse.data);
      setPatientID(user_id);
      setTrID(treatmentID);
    }
  }, [
    patientResponse,
    setPatientData,
    setPatientID,
    setTrID,
    treatmentID,
    user_id,
  ]);

  if (patientResponse.isLoading) {
    return <div className="load_spinner"></div>;
  }

  if (patientResponse.isError) {
    return <div className="error">Something went wrong</div>;
  }

  if (patientData && patientResponse.isSuccess && patientID && trID) {
    return <TreatmentMain />;
  } else {
    return <div className="load_spinner"></div>;
  }
};

export default Treatment;

import React, { useContext } from "react";
import { MainContext } from "../MainContext";

const PatientGeneral = () => {
  const { patientData } = useContext(MainContext);
  return (
    <>
      <h4>Medistiinilised detaiid</h4>
      <div className="sidepanel_item">
        <table>
          <tbody>
            <tr>
              <td>
                <h2>pikkus</h2>
              </td>
              <td>{patientData.height}</td>
            </tr>
            <tr>
              <td>
                <h2>kaal</h2>
              </td>
              <td>{patientData.weight}</td>
            </tr>
            <tr>
              <td>
                <h2>allergiad</h2>
              </td>
              <td>{patientData.allergies}</td>
            </tr>
            <tr>
              <td>
                <h2>ravimid</h2>
              </td>
              <td>{patientData.medications}</td>
            </tr>
            <tr>
              <td>
                <h2>eelnenud haigused</h2>
              </td>
              <td>{patientData.medical_history}</td>
            </tr>
            <tr>
              <td>
                <h2>kroonilised haigused</h2>
              </td>
              <td>{patientData.chronic_diseases}</td>
            </tr>
            <tr>
              <td>
                <h2>üldine tervis</h2>
              </td>
              <td>{patientData.general_health}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </>
  );
};

export default PatientGeneral;

import React, { useContext, useState } from "react";
import { MainContext } from "../MainContext";
import TreatmentComment from "./TreatmentComment";
import TreatmentComments from "./TreatmentComments";
import Diagnosis from "../../modules/medic/Diagnosis";
import QChart from "../graph/Chart";

const TreatmentDetail = () => {
  const { treatmentData } = useContext(MainContext);
  const [sortOrder, setSortOrder] = useState("desc");

  const handleSortOrderToggle = () => {
    setSortOrder(sortOrder === "asc" ? "desc" : "asc");
  };

  const sortedTreatmentComments = [...treatmentData.comments].sort((a, b) =>
    sortOrder === "asc"
      ? new Date(a.c_date_added) - new Date(b.c_date_added)
      : new Date(b.c_date_added) - new Date(a.c_date_added)
  );

  const sortedTreatmentCommentsWrapper = (
    <TreatmentComments comments={sortedTreatmentComments} />
  );

  return (
    <>
      <QChart />
      <TreatmentComment />
      <div className="button_container_right">
        <button className="sort_button" onClick={handleSortOrderToggle}>
          {/* {sortOrder === "asc" ? "Sorteeri kahanevalt" : "Sorteeri kasvavalt"} */}
        </button>
      </div>
      <div className="patient_treatment_wrapper">
        {sortOrder === "asc" && <Diagnosis />}
        <div className="treatment_comments_wrapper">
          <div className="treatment_comments_header"></div>
          {sortedTreatmentCommentsWrapper}
        </div>
        {sortOrder === "desc" && <Diagnosis />}
      </div>
    </>
  );
};

export default TreatmentDetail;

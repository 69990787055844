import React, { useContext, useEffect } from "react";

import { MainContext } from "../../common/MainContext";
import { useParams } from "react-router-dom";
import { useApi } from "../../common/fetch/DataFetch";

import PatientDetail from "../../common/views/PatientDetail";
import TreatmentList from "./TreatmentList";
import PatientGeneral from "../../common/views/PatientGeneral";
import PatientMedical from "../../common/views/PatientMedical";

const Patient = () => {
  const { user_id } = useParams();
  const { role, setPatientData, setPatientID, patientData, patientID } =
    useContext(MainContext);

  const url = `/web/user/patient-detail?role=${role}&patient_id=${user_id}`;
  const key = `patientData-${user_id}`;
  const patientResponse = useApi(key, url);

  useEffect(() => {
    if (patientResponse.data) {
      setPatientData(patientResponse.data);
      setPatientID(user_id);
    }
  }, [patientResponse, setPatientData, setPatientID, user_id]);

  if (patientResponse.isLoading) {
    return <div className="load_spinner"></div>;
  }

  if (patientResponse.isError) {
    return <div className="error">Something went wrong</div>;
  }

  if (patientData && patientID) {
    return (
      <div className="container">
        <div className="sidepanel">
          <PatientGeneral />
          <PatientMedical />
          <TreatmentList />
        </div>
        <div className="main">
          <PatientDetail />
        </div>
      </div>
    );
  } else {
    return <div className="load_spinner"></div>;
  }
};

export default Patient;

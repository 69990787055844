import React, { useContext, useEffect, useState } from "react";
import { Line } from "react-chartjs-2";
import Chart from "chart.js/auto";

import { MainContext } from "../../common/MainContext";

import { useApi } from "../../common/fetch/DataFetch";

import { MonthNames } from "./Labels";

Chart.defaults.font.family = "'Nunito Sans', sans-serif";

const EekChart = ({ queryYear, checkboxState }) => {
  const { patientID } = useContext(MainContext);
  const [queryData, setQueryData] = useState(null);

  const url = `/web/q/questionaire1v1?patient_id=${patientID}&year=${queryYear}`;
  const key = `questionaiere1-${patientID}-${queryYear}`;
  const questionaireData = useApi(key, url);

  useEffect(() => {
    if (questionaireData.data && questionaireData.isSuccess) {
      setQueryData(questionaireData.data.responses);
    }
  }, [questionaireData]);

  const getTooltip = (item) => {
    const { date, tooltip } = item;
    const tooltipTitle = `Kuupäev  ${date}`;
    const tooltipData = Object.entries(tooltip).map(([key, value]) => ({
      label: key,
      value: value,
    }));
    return {
      title: tooltipTitle,
      data: tooltipData,
    };
  };

  const options = {
    spanGaps: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        display: false,
      },
      tooltip: {
        callbacks: {
          title: function (tooltipItems) {
            let tooltipItem = tooltipItems[0];
            if (
              !tooltipItem ||
              !tooltipItem.dataset ||
              !tooltipItem.dataset.tooltip ||
              !tooltipItem.dataset.tooltip[tooltipItem.dataIndex]
            ) {
              return;
            }
            return tooltipItem.dataset.tooltip[tooltipItem.dataIndex].title;
          },
          label: function (tooltipItem) {
            if (
              !tooltipItem ||
              !tooltipItem.dataset ||
              !tooltipItem.dataset.tooltip ||
              !tooltipItem.dataset.tooltip[tooltipItem.dataIndex] ||
              !tooltipItem.dataset.tooltip[tooltipItem.dataIndex].data
            ) {
              return;
            }
            let labels = tooltipItem.dataset.tooltip[
              tooltipItem.dataIndex
            ].data.map((obj) => `${obj.label} ${obj.value}`);
            return labels;
          },
        },
      },
    },
    scales: {
      y: {
        display: true,
        grid: {
          display: true,
        },
        ticks: {
          beginAtZero: true,
          stepSize: 1,
          grace: "6%",
        },
      },
      x: {
        display: true,
        grid: {
          display: true,
        },
        ticks: {
          callback: function (value, index, values) {
            return MonthNames[index];
          },
        },
      },
    },
  };

  const getDataset = (title, color) => {
    const data = [];
    const tooltip = [];
    let FlatQueryData = [];
    (queryData || []).forEach((response) => {
      Object.entries(response).forEach(([key, value]) => {
        FlatQueryData.push(value);
      });
    });
    FlatQueryData = FlatQueryData.flat();

    MonthNames.forEach((month) => {
      let item = FlatQueryData.find(
        (response) => response.title === title && response.label === month
      );
      if (item) {
        data.push(item.value);
      } else {
        data.push(NaN);
      }
    });

    MonthNames.forEach((month) => {
      let item = FlatQueryData.find(
        (response) => response.title === title && response.label === month
      );
      if (item) {
        tooltip.push(getTooltip(item));
      } else {
        tooltip.push(null);
      }
    });
    const dataset = {
      label: title,
      data,
      fill: false,
      borderColor: color,
      tooltip,
    };
    return dataset;
  };

  const data = {
    labels: MonthNames,
    datasets: [
      checkboxState.depression && getDataset("Depressioon", "#2C54A3"),
      checkboxState.anxiety && getDataset("Ärevushäire", "#e8c048"),
      checkboxState.panic && getDataset("Paanikahood", "#43F126"),
      checkboxState.social && getDataset("Sotsiaalne ärevushäire", "#A78C8C"),
      checkboxState.asthenia && getDataset("Asteenia", "#C268D9"),
      checkboxState.sleep && getDataset("Unehäired", "#E16565"),
    ],
  };

  return <Line data={data} options={options} />;
};

export default EekChart;

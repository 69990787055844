import React, { useContext, useEffect, useState } from "react";
import { Route, Routes } from "react-router-dom";

import { MainContext } from "../../common/MainContext";
import { useApi } from "../../common/fetch/DataFetch";
import TreatmentTeam from "../../common/views/TreatmentTeam";
import TreatmentPlan from "../../common/views/TreatmentPlan";
import PatientQuestionaireMenu from "./PatientQuestionaireMenu";
import TreatmentContent from "../../common/views/TreatmentContent";
import PatientQuestionaire from "../../common/views/PatientQuestionaire";
import ToggleTreatments from "./ToggleTreatments";

const PatientTreatment = () => {
  const { treatmentData, setTreatmentData, userID, setTrID, treatmentList } =
    useContext(MainContext);

  // /web/treatment/details/{treatment_id}/{patient_id}
  const sortedTreatmentList = treatmentList.sort(
    (a, b) => new Date(b.date_added) - new Date(a.date_added)
  );

  const [newestTreatmentId, setNewestTreatmentId] = useState(
    sortedTreatmentList[0].treatment_id
  );

  const url = `/web/treatment/details/${newestTreatmentId}/${userID}`;
  const key = `treatmentData-${newestTreatmentId}`;
  const treatmentDataResponse = useApi(key, url);

  useEffect(() => {
    if (treatmentDataResponse.data && treatmentDataResponse.isSuccess) {
      setTreatmentData(treatmentDataResponse.data.treatment);
      setTrID(newestTreatmentId);
    }
  }, [treatmentDataResponse, newestTreatmentId, setTreatmentData, setTrID]);

  if (treatmentDataResponse.isLoading) {
    return <div className="load_spinner"></div>;
  }

  if (treatmentDataResponse.isError) {
    return <div className="error">Something went wrong</div>;
  }

  if (treatmentData && treatmentDataResponse.isSuccess) {
    return (
      <div className="container">
        <div className="sidepanel">
          {treatmentList && treatmentList.length > 1 && (
            <ToggleTreatments
              setTrPlan={setNewestTreatmentId}
              newTrId={newestTreatmentId}
              setTrID={setTrID}
            />
          )}
          <PatientQuestionaireMenu />
          {treatmentData &&
            treatmentData.treatment_plan &&
            treatmentData.treatment_plan.length > 0 && <TreatmentPlan />}
          <TreatmentTeam />
        </div>
        <div className="main">
          <Routes>
            <Route path="*" element={<TreatmentContent />} />
            <Route path="questionaire/eek" element={<PatientQuestionaire />} />
          </Routes>
        </div>
      </div>
    );
  } else {
    return <div className="load_spinner"></div>;
  }
};

export default PatientTreatment;

import React, { useContext, useState } from "react";
import { MainContext } from "../MainContext";
import { useNavigate, Link } from "react-router-dom";

import { useApiMutation } from "../fetch/DataFetch";

import { Translations, ButtonLabels } from "../graph/Labels";

const PatientQuestionaire = () => {
  const navigate = useNavigate();
  const { patientID } = useContext(MainContext);
  const translations = Translations();
  const buttonLabels = ButtonLabels();
  const optionValues = Object.keys(buttonLabels).map((key) => ({
    value: parseInt(key, 10),
    label: buttonLabels[key],
  }));
  const questionKeys = Object.keys(translations);

  const [selectedOptions, setSelectedOptions] = useState(
    questionKeys.reduce((acc, key) => {
      acc[key] = null;
      return acc;
    }, {})
  );
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(null);
  const [toggle, setToggle] = useState(null);

  const addQuestionairetMutation = useApiMutation(
    "POST",
    "/web/q/questionaire1",
    {
      onSuccess: () => {
        setSuccess("Vastused edukalt saadetud!");
        setToggle(1);
      },
      onError: (error) => {
        setError(error);
      },
    }
  );

  const handleButtonClick = (questionKey, value) => {
    setSelectedOptions({ ...selectedOptions, [questionKey]: value });
  };

  const allQuestionsAnswered = Object.values(selectedOptions).every(
    (value) => value !== null
  );

  const preparePayload = () => {
    return {
      ...selectedOptions,
      patient_id: patientID,
    };
  };

  const handleSubmit = async () => {
    const payload = preparePayload();
    try {
      if (payload) {
        const response = await addQuestionairetMutation.mutateAsync(payload);
        if (response) {
          setSuccess("Vastused edukalt saadetud!");
          setError("");
          setTimeout(() => setSuccess(""), 3000);
        }
      }
    } catch (error) {
      setTimeout(() => setError(error), 3000);
    }
  };

  return (
    <>
      <div className="questionaire_header">
        <div className="questionaire_title">
          <h4>Enesetunde küsimustik</h4>
        </div>
        <div className="questionaire_back">
          <Link className="green_link" onClick={() => navigate(-1)}>
            Tagasi
          </Link>
        </div>
      </div>

      <div className="questionaire_container">
        <div className="questionnaire">
          <p>
            Palun hinda iga probleemi kohta, kuivõrd see on Sind häirinud
            viimase kuu jooksul.
          </p>

          {questionKeys.map((key, index) => (
            <div key={index} className="question">
              <p>{translations[key]}</p>
              <div className="questionaire_options">
                {optionValues.map(({ value, label }, buttonIndex) => (
                  <button
                    key={value}
                    className={`${
                      selectedOptions[key] === value ? "selected" : "unselected"
                    } ${
                      buttonIndex === 0
                        ? "first"
                        : buttonIndex === 4
                        ? "last"
                        : ""
                    }`}
                    onClick={() => handleButtonClick(key, value)}
                  >
                    {label}
                  </button>
                ))}
              </div>
            </div>
          ))}
          <div className="send_container">
            <div className="msg_container_button">
              {toggle === 1 ? (
                <button
                  className="submit"
                  type="submit"
                  onClick={() => navigate(-1)}
                >
                  Tagasi
                </button>
              ) : (
                <button
                  className="submit"
                  disabled={!allQuestionsAnswered}
                  onClick={handleSubmit}
                >
                  Saada vastused
                </button>
              )}
            </div>
            <div className="notification_center">
              <div className="error">{error}</div>
              <div className="success">{success}</div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default PatientQuestionaire;

import React, { useContext } from "react";
import { MainContext } from "../MainContext";
import { Link } from "react-router-dom";

const PatientGeneral = () => {
  const { patientData, patientID } = useContext(MainContext);

  return (
    <>
      <h4>Profiil</h4>
      <div className="sidepanel_item">
        <table>
          <tbody>
            <tr>
              <td>
                <h2>nimi</h2>
              </td>
              <td>
                {patientData.first_name} {patientData.last_name}
              </td>
            </tr>
            <tr>
              <td>
                <h2>isikukood</h2>
              </td>
              <td>{patientData.social_security}</td>
            </tr>
            <tr>
              <td>
                <h2>kindlustatud</h2>
              </td>
              <td>{patientData.insured ? "jah" : "ei"}</td>
            </tr>
            <tr>
              <td>
                <h2>sünniaasta</h2>
              </td>
              <td>{patientData.dob}</td>
            </tr>
            <tr>
              <td>
                <h2>sugu</h2>
              </td>
              <td>{patientData.gender}</td>
            </tr>
            <tr>
              <td>
                <h2>keel</h2>
              </td>
              <td>{patientData.language}</td>
            </tr>
            <tr>
              <td>
                <h2>linn</h2>
              </td>
              <td>{patientData.city}</td>
            </tr>
            <tr>
              <td>
                <h2>telefon</h2>
              </td>
              <td>{patientData.phone_number}</td>
            </tr>
            <tr>
              <td>
                <h2>epost</h2>
              </td>
              <td>{patientData.email}</td>
            </tr>
            <tr>
              <td>
                <h2>töökoht</h2>
              </td>
              <td>{patientData.work_place}</td>
            </tr>
            <tr>
              <td>
                <h2>amet</h2>
              </td>
              <td>{patientData.work_position}</td>
            </tr>
          </tbody>
        </table>
        <div className="button_container_right">
          <Link to={"/medic/patients/" + patientID}>
            <button className="arrow_button"></button>
          </Link>
        </div>
      </div>
    </>
  );
};

export default PatientGeneral;

import React, { StrictMode } from "react";
import { createRoot } from "react-dom/client";
import "./css/index.css";

import "./css/login.css";
import "./css/headerElem.css";
import "./css/sidepanelElem.css";
import "./css/mainElem.css";
import "./css/button.css";
import "./css/input.css";
import "./css/chart.css";
import "./css/text.css";
import "./css/questionaire.css";
import "./css/treatment.css";
import "./css/comment.css";

import "./css/links.css";
import "./css/search.css";
import "./css/mobile.css";
import "./css/misc.css";

import "./css/notifications.css";

import App from "./App";
import { MainProvider } from "./common/MainContext";
import { QueryClient, QueryClientProvider } from "react-query";
import { BrowserRouter as Router } from "react-router-dom";

const rootElement = document.getElementById("root");
const root = createRoot(rootElement);
const queryClient = new QueryClient();

root.render(
  <StrictMode>
    <Router>
      <QueryClientProvider client={queryClient}>
        <MainProvider>
          <App />
        </MainProvider>
      </QueryClientProvider>
    </Router>
  </StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();

import React, { useState } from "react";

const AddTreatmentTrPlan = ({ treatmentPlan, setTreatmentPlan }) => {
  const [trPlans, setTrPlans] = useState([
    { treatment: "", treatment_detail: "" },
  ]);

  const handleChange = (e, index) => {
    let { name, value } = e.target;
    let plans = [...trPlans];
    plans[index] = {
      ...plans[index],
      [name]: value,
    };
    setTrPlans(plans);
    setTreatmentPlan(plans); // update treatmentPlan whenever trPlans change
  };

  const addTreatmentPlan = () => {
    const newPlan = { treatment: "", treatment_detail: "" };
    setTrPlans([...trPlans, newPlan]);
  };

  const removeTreatmentPlan = () => {
    if (trPlans.length > 1) {
      let plans = [...trPlans];
      plans.pop();
      setTrPlans(plans);
    }
  };

  return (
    <>
      <h4>Ravi</h4>
      <div className="main_form">
        <form>
          {trPlans.map((trPlan, index) => (
            <div key={index}>
              <label htmlFor={`treatment-${index}`}>Ravi</label>
              <textarea
                rows="2"
                maxLength="10000"
                type="text"
                name="treatment"
                value={trPlan.treatment}
                onChange={(e) => handleChange(e, index)}
              />
              <label htmlFor={`treatment_detail-${index}`}>Ravi detailid</label>
              <textarea
                rows="2"
                maxLength="1000"
                type="text"
                name="treatment_detail"
                value={trPlan.treatment_detail}
                onChange={(e) => handleChange(e, index)}
              />
            </div>
          ))}
          <div className="button_container_center">
            {trPlans.length > 1 && (
              <button
                className="remove_button"
                title="Eemalda viimane raviplaan"
                onClick={removeTreatmentPlan}
              />
            )}
            <button
              type="button"
              className="add_button"
              onClick={addTreatmentPlan}
              title="Lisa veel raviplaane"
            />
          </div>
        </form>
      </div>
    </>
  );
};

export default AddTreatmentTrPlan;

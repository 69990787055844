import React from "react";

const ProfileDiagnosis = ({ diagnosis, setDiagnosis }) => {
  const handleChange = (e) => {
    let { name, value } = e.target;
    setDiagnosis({
      ...diagnosis,
      [name]: value,
    });
  };
  return (
    <>
      <h4>Diagnoos, anamnees</h4>
      <div className="main_form">
        <form>
          <label htmlFor="diagnosis">Diagnoos *</label>
          <textarea
            rows="2"
            maxLength="10000"
            type="text"
            name="diagnosis"
            value={diagnosis.diagnosis}
            onChange={handleChange}
          />
          <label htmlFor="anamnesis">Anamnees *</label>
          <textarea
            rows="2"
            maxLength="1000"
            type="text"
            name="anamnesis"
            value={diagnosis.anamnesis}
            onChange={handleChange}
          />
        </form>
      </div>
    </>
  );
};

export default ProfileDiagnosis;

import React, { useState } from "react";

// store state and dispatch in context
const MainContext = React.createContext();

// create provider
const MainProvider = ({ children }) => {
  const [jwt, setJwt] = useState(localStorage.getItem("token"));
  const [userID, setUserID] = useState(localStorage.getItem("userID"));
  const [role, setRole] = useState(localStorage.getItem("role"));
  const [trID, setTrID] = useState(null);
  const [treatmentList, setTreatmentList] = useState([]);
  const [status, setStatus] = useState({
    error: null,
    success: null,
    loading: false,
  });
  const [error, setError] = useState(null);
  const [treatmentData, setTreatmentData] = useState(null);
  const [patientData, setPatientData] = useState(null);
  const [patientID, setPatientID] = useState(null);
  const [treatmentComments, setTreatmentComments] = useState(null);
  return (
    <MainContext.Provider
      value={{
        jwt,
        setJwt,
        userID,
        setUserID,
        role,
        setRole,
        status,
        setStatus,
        error,
        setError,
        treatmentData,
        setTreatmentData,
        patientData,
        setPatientData,
        treatmentComments,
        setTreatmentComments,
        trID,
        setTrID,
        patientID,
        setPatientID,
        treatmentList,
        setTreatmentList,
      }}
    >
      {children}
    </MainContext.Provider>
  );
};

export { MainContext, MainProvider };

import React, { useEffect, useState } from "react";

import { dateTimeDate } from "../../common/DateTime";
import { useApi } from "../../common/fetch/DataFetch";

import { useNavigate } from "react-router-dom";

const PendingInvites = () => {
  const navigate = useNavigate();
  const [pendingInvites, setPendingInvites] = useState(null);
  const url = `/web/medic/invites-pending`;
  const key = `invites-pending`;
  const response = useApi(key, url);

  useEffect(() => {
    if (response) {
      setPendingInvites(response.data);
    }
  }, [response]);

  if (response.isLoading) {
    return <div className="load_spinner"></div>;
  }

  if (response.isError) {
    return <div className="error">Something went wrong</div>;
  }

  if (pendingInvites) {
    return (
      <div className="container">
        <div className="sidepanel">
          <h4>Patsiendi kinnitust ootavad kutsed</h4>
          <div className="sidepanel_item">
            {pendingInvites.invites.map(
              (
                {
                  first_name,
                  last_name,
                  email,
                  phone_number,
                  dob,
                  gender,
                  diagnosis,
                  treatment,
                  social_security,
                  date_added,
                },
                key
              ) => (
                <div className="text_block" key={key}>
                  <h6>
                    {first_name} {last_name}
                  </h6>
                  <p>{social_security}</p>
                  <p>
                    {email} {phone_number}
                  </p>{" "}
                  {dob}
                  <p>{diagnosis}</p>
                  <p> {treatment}</p> {dateTimeDate(date_added)}
                </div>
              )
            )}
          </div>
          <div className="button_container_right">
            <button
              className="button_inverted"
              type="submit"
              onClick={() => navigate(-1)}
            >
              Tagasi
            </button>
          </div>
        </div>
      </div>
    );
  } else {
    return (
      <div className="button_container_right">
        <button
          className="button_inverted"
          type="submit"
          onClick={() => navigate(-1)}
        >
          Tagasi
        </button>
      </div>
    );
  }
};

export default PendingInvites;

// POST treatment comment

import React, { useState, useContext } from "react";
import { useQueryClient } from "react-query";

import { MainContext } from "../MainContext";
import { useApiMutation } from "../fetch/DataFetch";

const TreatmentComment = () => {
  const { trID, patientID } = useContext(MainContext);
  const queryClient = useQueryClient();
  const [success, setSuccess] = useState(null);
  const [message, setMessage] = useState("");
  const [error, setError] = useState("");
  const [formdata, setFormData] = useState({
    comment: "",
    treatment_id: trID,
  });
  const handleChange = (e) => {
    setFormData({
      ...formdata,
      [e.target.name]: e.target.value,
    });
    setSuccess("");
    setError("");
  };

  const addCommentMutation = useApiMutation("POST", "/web/treatment/comment", {
    onSuccess: () => {
      queryClient.invalidateQueries(`treatmentData-${trID}-${patientID}`); // Invalidate treatment query
    },
    onError: (error) => {
      setError(error);
    },
  });

  const handleSubmit = async (e) => {
    e.preventDefault();
    const form = e.currentTarget;

    form.disabled = true;
    try {
      if (formdata) {
        const response = await addCommentMutation.mutateAsync(formdata);
        if (response) {
          setSuccess(response);
          setFormData({
            comment: "",
            treatment_id: trID,
          });
          setError("");
          setMessage("Kommentaar lisatud!");
          setTimeout(() => setMessage(""), 3000);
        }
      }
    } catch (error) {
      setError(error);
    }
    form.disabled = false;
  };
  return (
    <div>
      <div className="comment">
        <form className="" onSubmit={handleSubmit}>
          <label htmlFor="">comment</label>
          <textarea
            placeholder="Lisa uus sissekanne.."
            name="comment"
            rows="2"
            maxLength="10000"
            value={formdata.comment}
            onChange={handleChange}
          />
          <br />
          <button type="submit" value="Submit">
            Lisa postitus
          </button>
        </form>
      </div>
      <div className="notification_right">
        {success && <div className="success">{message}</div>}
        {error && <div className="error">{error.message}</div>}
      </div>
    </div>
  );
};

export default TreatmentComment;

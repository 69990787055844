import React from "react";

import { Route, Routes, Link } from "react-router-dom";

import LogOut from "../../common/auth/LogOut";
import PatientSearch from "./PatientSearch";
import Patient from "./Patient";
import Treatment from "./Treatment";
import AddTreatment from "./AddTreatment";
import PendingInvites from "./PendingInvites";
import EditPatient from "../../common/views/EditPatient";
import PatientQuestionaire from "../../common/views/PatientQuestionaire";
import TreatmentContent from "../../common/views/TreatmentContent";
import PatientList from "./PatientList";
import PatientListLink from "./PatientListLink";

import { DateString } from "../../common/DateTime";

export default function MedicHome() {
  return (
    <>
      <div className="header">
        <div className="header_left_menu">
          <PatientSearch />
          <span>
            <PatientListLink />
          </span>
          <span className="mobile_hide_wrapper">
            <Link to="/medic/new-treatment/">Alusta uut ravi </Link>
          </span>
        </div>
        <div className="current_date">{DateString()}</div>
        <div className="header_right_menu ">
          <span className="mobile_hide_wrapper">
            <Link to="/medic/invites-pending/">Saadetud kutsed</Link>
          </span>
          <span>
            <LogOut />
          </span>
        </div>
      </div>

      <Routes>
        <Route path="/invites-pending/" element={<PendingInvites />} />
        <Route path="/new-treatment" element={<AddTreatment />} />
        <Route path="/patients/list/" element={<PatientList />} />
        <Route path="/patients/:user_id/*" element={<Patient />} />

        <Route
          path="/patients/:user_id/treatments/:treatmentID"
          element={<Treatment />}
        >
          <Route index element={<TreatmentContent />} />
          <Route path="questionaire" element={<PatientQuestionaire />} />
        </Route>
        <Route path="/patients/:patientID/edit" element={<EditPatient />} />
      </Routes>
    </>
  );
}

import React, { useContext, useEffect } from "react";
import { MainContext } from "../../common/MainContext";
import { useApi } from "../../common/fetch/DataFetch";

import { Outlet } from "react-router-dom";

import PatientGeneral from "../../common/views/PatientGeneral";
import PatientMedical from "../../common/views/PatientMedical";
import TreatmentTeam from "../../common/views/TreatmentTeam";
import TreatmentPlan from "../../common/views/TreatmentPlan";
import QuestionaireMenu from "./QuestionaireMenu";

const TreatmentMain = () => {
  const { patientID, setTreatmentData, treatmentData, trID } =
    useContext(MainContext);

  const trUrl = `/web/treatment/details/${trID}/${patientID}`;
  const trKey = `treatmentData-${trID}-${patientID}`;
  const trResp = useApi(trKey, trUrl);

  useEffect(() => {
    if (trResp.isSuccess) {
      setTreatmentData(trResp.data.treatment);
    }
  }, [trResp, setTreatmentData]);

  if (trResp.isLoading) {
    return <div className="load_spinner"></div>;
  }

  if (trResp.isError) {
    return <div className="error"> Something went wrong </div>;
  }

  if (trResp.isSuccess && treatmentData && patientID && trID) {
    return (
      <div className="container">
        <div className="sidepanel">
          <PatientGeneral />
          <PatientMedical />
          <TreatmentPlan />
          <TreatmentTeam />
          <QuestionaireMenu />
        </div>
        <div className="main">
          <>
            <Outlet />
          </>
        </div>
      </div>
    );
  } else {
    return <div className="load_spinner"></div>;
  }
};

export default TreatmentMain;

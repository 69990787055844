import React from "react";
import { Link, useParams } from "react-router-dom";

const QuestionaireMenu = () => {
  const { user_id, treatmentID } = useParams();

  return (
    <div className="mobile_hide_wrapper">
      <p className="side_panel_heading">Küsimustikud</p>
      <div className="sidepanel_item">
        <span>
          {" "}
          <Link
            to={`/medic/patients/${user_id}/treatments/${treatmentID}/questionaire`}
          >
            Emotsionaalse enesetunde küsimustik
          </Link>
        </span>
      </div>
    </div>
  );
};
export default QuestionaireMenu;

import React, { useContext } from "react";
import { useQueryClient } from "react-query";

import { MainContext } from "../../common/MainContext";
import { useApiMutation } from "../../common/fetch/DataFetch";

const TreatmentCommentDelete = ({ TreatmentCommentID, CommentatorID }) => {
  const queryClient = useQueryClient();
  const { trID, patientID, userID } = useContext(MainContext);
  const formData = {
    treatment_comment_id: TreatmentCommentID,
    treatment_id: trID,
  };

  const addCommentMutation = useApiMutation("PUT", "/web/treatment/comment", {
    onSuccess: () => {
      queryClient.invalidateQueries(`treatmentData-${trID}-${patientID}`); // Invalidate treatment query
    },
  });

  const handleSubmit = async (e) => {
    e.preventDefault();
    const confirmed = window.confirm(
      "Oled kindel, et soovid selle kommentaari kustutada?"
    );
    if (confirmed) {
      await addCommentMutation.mutateAsync(formData);
    }
  };
  if (userID !== CommentatorID) return null;
  return (
    <div className="button_container_right">
      <button
        className="hide_button"
        title="kustuta kommentaar"
        onClick={handleSubmit}
      />
    </div>
  );
};

export default TreatmentCommentDelete;

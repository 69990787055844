export const Labels = () => [
  "Jaanuar",
  "Veebruar",
  "Märts",
  "Aprill",
  "Mai",
  "Juuni",
  "Juuli",
  "August",
  "September",
  "Oktoober",
  "November",
  "Detsember",
];

export const MonthNames = [
  "Jaanuar",
  "Veebruar",
  "Märts",
  "Aprill",
  "Mai",
  "Juuni",
  "Juuli",
  "August",
  "September",
  "Oktoober",
  "November",
  "Detsember",
];

export const sleepingHours = () => {
  const d = {
    2017: { Uni: [0, 0, 0, 0, 0, 8, 7, 8, 9, 11, 11, 5] },
    2018: { Uni: [8, 7, 8, 7, 8, 8, 7, 8, 8, 7, 9, 8] },
    2019: { Uni: [0, 0, 0, 0, 0, 8, 7, 8, 9, 11, 11, 5] },
    2020: { Uni: [6, 6, 4, 5, 4, 6, 7, 8, 8, 9, 8, 8] },
    2021: { Uni: [6, 6, 7, 8, 0, 0, 0, 0, 7, 7, 8, 7] },
    2022: { Uni: [8, 7, 8, 7, 9, 9, 9, 8, 7, 8, 6, 7] },
    2023: { Uni: [7, 6, 5, 5] },
  };
  return d;
};

export const Translations = () => {
  // Both graph and questionnaire use this
  // Questionarie skips first 6
  // Last 5 are used for graph buttons. Please don't change the order nor the lenght of this array
  const translations = {
    sadness: "Kurvameelsus ",
    loss_of_interest: "Huvi kadumine ",
    feeling_of_inferiority: "Alaväärsustunne ",
    self_recriminations: "Enesesüüdistused ",
    thoughts_of_death_or_suicide: "Korduvad surma- või enesetapumõtted",
    feeling_of_loneliness: "Üksildustunne ",
    hopelessness_about_future: "Lootusetus tuleviku suhtes ",
    inability_to_feel_joy: "Võimetus rõõmu tunda ",
    upset_or_angry_quickly: "Kiire ärritumine või vihastamine ",
    feeling_of_anxiety_or_fear: "Ärevuse- või hirmutunne ",
    tense_or_unable_to_relax: "Pingetunne või võimetus lõdvestuda ",
    worrying_too_much: "Liigne muretsemine paljude asjade pärast ",
    restlessness_or_impatience:
      "Rahutus või kärsitus, nii et ei suuda paigal püsida ",
    easily_startled: "Kergesti ehmumine ",
    sudden_panic_attacks:
      "Äkilised paanikahood, mille ajal esinevad südamekloppimine, õhupuudus, minestamistunne või muud hirmutavad kehalised nähud",
    fear_being_alone_away_from_home: "Kartus viibida üksi kodust eemal ",
    feeling_of_fear_in_public_places:
      "Hirmutunne avalikes kohtades või tänavatel ",
    fear_of_fainting: "Kartus minestada rahva hulgas ",
    afraid_to_drive: "Kartus sõita bussi, trammi, rongi või autoga ",
    fear_of_being_the_center_of_attention: "Hirm olla tähelepanu keskpunktis ",
    fear_of_communicating_with_strangers:
      "Hirm suhtlemisel võõraste inimestega ",
    feeling_sluggish_or_tired: "Loidus- või väsimustunne ",
    decreased_ability_to_concentrate:
      "Vähenenud tähelepanu- või keskendumisvõime ",
    rest_does_not_restore_strength: "Puhkamine ei taasta jõudu ",
    fatigue_quickly: "Kiire väsimine ",
    difficulty_falling_asleep: "Uinumisraskused ",
    restless_or_interrupted_sleep: "Rahutu või katkendlik uni ",
    Waking_up_too_early: "Liigvarajane ärkamine",
  };
  return translations;
};

export const TooltipSummaryLabel = () => {
  const d = {
    depression: "Depressioon (>11)",
    anxiety: "Ärevushäire (>11)",
    panic: "Paanikahood (>6)",
    social: "Sotsiaalne ärevushäire (>3)",
    asthenia: "Asteenia (>6)",
    sleep: "Unehäired (>5)",
  };
  return d;
};

export const CheckboxLabel = () => {
  const d = {
    depression: "Depressioon",
    anxiety: "Ärevushäire",
    panic: "Paanikahood",
    social: "Sotsiaalne ärevushäire",
    asthenia: "Asteenia",
    sleep: "Unehäired",
  };
  return d;
};

export const GraphLabels = () => {
  // Both graph and questionnaire use this
  // Questionarie skips first 6
  // Last 5 are used for graph buttons. Please don't change the order nor the lenght of this array
  const l = {
    sadness: "Kurvameelsus ",
    loss_of_interest: "Huvi kadumine ",
    feeling_of_inferiority: "Alaväärsustunne ",
    self_recriminations: "Enesesüüdistused ",
    thoughts_of_death_or_suicide: "Korduvad surma- või enesetapumõtted",
    feeling_of_loneliness: "Üksildustunne ",
    hopelessness_about_future: "Lootusetus tuleviku suhtes ",
    inability_to_feel_joy: "Võimetus rõõmu tunda ",
    upset_or_angry_quickly: "Kiire ärritumine või vihastamine ",
    feeling_of_anxiety_or_fear: "Ärevuse- või hirmutunne ",
    tense_or_unable_to_relax: "Pingetunne või võimetus lõdvestuda ",
    worrying_too_much: "Liigne muretsemine paljude asjade pärast ",
    restlessness_or_impatience:
      "Rahutus või kärsitus, nii et ei suuda paigal püsida ",
    easily_startled: "Kergesti ehmumine ",
    sudden_panic_attacks: "Äkilised paanikahood",
    fear_being_alone_away_from_home: "Kartus viibida üksi kodust eemal ",
    feeling_of_fear_in_public_places:
      "Hirmutunne avalikes kohtades või tänavatel ",
    fear_of_fainting: "Kartus minestada rahva hulgas ",
    afraid_to_drive: "Kartus sõita bussi, trammi, rongi või autoga ",
    fear_of_being_the_center_of_attention: "Hirm olla tähelepanu keskpunktis ",
    fear_of_communicating_with_strangers:
      "Hirm suhtlemisel võõraste inimestega ",
    feeling_sluggish_or_tired: "Loidus- või väsimustunne ",
    decreased_ability_to_concentrate:
      "Vähenenud tähelepanu- või keskendumisvõime ",
    rest_does_not_restore_strength: "Puhkamine ei taasta jõudu ",
    fatigue_quickly: "Kiire väsimine ",
    difficulty_falling_asleep: "Uinumisraskused ",
    restless_or_interrupted_sleep: "Rahutu või katkendlik uni ",
    Waking_up_too_early: "Liigvarajane ärkamine",
    0: "Üldse mitte",
    1: "Harva",
    2: "Mõnikord",
    3: "Sageli",
    4: "Pidevalt",
  };
  return l;
};

export const ButtonLabels = () => {
  const l = {
    0: "Üldse mitte",
    1: "Harva",
    2: "Mõnikord",
    3: "Sageli",
    4: "Pidevalt",
  };
  return l;
};

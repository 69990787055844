import React from "react";
import { useEffect, useState, useContext } from "react";

import { MainContext } from "../MainContext";
import { useApi } from "../fetch/DataFetch";

import AddTreatmentTeam from "../../modules/medic/AddTreatmentTeam";
import ToggleTreatmentTeam from "../../modules/patient/ToggleTreatmentTeam";
import icon1 from "../../img/medics/1.svg";
import icon2 from "../../img/medics/2.svg";
import icon3 from "../../img/medics/3.svg";
import icon4 from "../../img/medics/4.svg";
import icon5 from "../../img/medics/5.svg";
import icon6 from "../../img/medics/6.svg";

const TreatmentTeam = () => {
  const { trID, role } = useContext(MainContext);
  const [treatmentteam, setTreatmentTeam] = useState([]);
  const icons = [icon1, icon2, icon3, icon4, icon5, icon6];
  const url = `/web/treatment/treatment-team/${trID}?role=${role}`;
  const queryKey = `treatmentTeam-${trID}`;
  const response = useApi(queryKey, url);

  useEffect(() => {
    if (response.data) {
      setTreatmentTeam(response.data.medics);
    }
  }, [response]);

  if (response.isLoading) {
    return <div className="load_spinner"></div>;
  }

  if (response.isError) {
    return <div className="error">Error</div>;
  }

  if (!treatmentteam) {
    return null;
  }

  return (
    <>
      {trID && treatmentteam && (
        <>
          <p className="side_panel_heading">Ravimeeskond</p>
          <div className="sidepanel_item">
            {treatmentteam.map(
              ({ first_name, last_name, title, consent, medic_id }, index) => (
                <div className="sidepanel_treatment_team" key={index}>
                  <span className={"name icon icon_" + (index % icons.length)}>
                    {title} {first_name} {last_name} {consent}
                    {role === "patient" &&
                    treatmentteam &&
                    treatmentteam.length > 1 ? (
                      <ToggleTreatmentTeam
                        consent={consent}
                        queryKey={queryKey}
                        medic_id={medic_id}
                      />
                    ) : null}
                  </span>
                </div>
              )
            )}
            {role === "medic" ? <AddTreatmentTeam /> : null}
          </div>
        </>
      )}
    </>
  );
};

export default TreatmentTeam;

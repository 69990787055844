import React, { useContext } from "react";
import { MainContext } from "../MainContext";
import { Link, useNavigate } from "react-router-dom";

const PatientGeneral = () => {
  const navigate = useNavigate();
  const { patientData, patientID, role } = useContext(MainContext);
  return (
    <>
      <h4>Huvid, harjumused ja perekond</h4>
      <div className="main_form">
        <label>Minust</label>
        <div className="text_block">{patientData.bio}</div>
        <label>Mida vajan enda ravimeeskonnalt</label>
        <div className="text_block">{patientData.expectations}</div>
        <label>Minu eelnevad ravi ja/või teraapia kogemused</label>
        <div className="text_block">{patientData.previous_exp}</div>
        <label>Minu huvid</label>
        <div className="text_block">{patientData.interests}</div>
        <label>Minu perekond</label>
        <div className="text_block">{patientData.family_status}</div>
        <label>Minu perekonna haigused</label>
        <div className="text_block">{patientData.family_history}</div>
        <label>Minu perekonna meditsiiniline ajalugu</label>
        <div className="text_block">{patientData.family_history}</div>
        <label>Minu tervislikud hobid</label>
        <div className="text_block">{patientData.healthy_habits}</div>
        <label>Minu pahed</label>
        <div className="text_block">{patientData.unhealthy_habits}</div>
        <label>Minu toitumisharjumused</label>
        <div className="text_block">{patientData.healthy_diet}</div>
        <label>Religioon</label>
        <div className="text_block">{patientData.religion}</div>
      </div>
      <div className="button_container_right">
        <button
          className="button_inverted"
          type="submit"
          onClick={() => navigate(-1)}
        >
          Tagasi
        </button>
        {role === "medic" && (
          <Link
            to={`/medic/patients/${patientID}/edit`}
            className="button_link"
          >
            Muuda andmeid
          </Link>
        )}
        {role === "patient" && (
          <Link to={`/patient/profile/edit/`} className="button_link">
            Muuda andmeid
          </Link>
        )}
      </div>
    </>
  );
};

export default PatientGeneral;

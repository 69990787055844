import React from "react";

const Profile = ({ profile, setProfile, date, setDate }) => {
  const handleChange = (e) => {
    let { name, value } = e.target;
    setProfile({
      ...profile,
      [name]: value,
    });
  };
  const handleDate = (e) => {
    let { name, value } = e.target;
    setDate({
      ...date,
      [name]: value,
    });
  };
  return (
    <>
      <h4>Patsiendi info</h4>
      <div className="sidepanel_item">
        <div className="side_form">
          <form>
            <label htmlFor="fname">Eesnimi *</label>
            <input
              className="line_input"
              type="text"
              name="first_name"
              value={profile.first_name}
              onChange={handleChange}
            />
            <label htmlFor="lname">Perekonnanimi *</label>
            <input
              className="line_input"
              type="text"
              name="last_name"
              value={profile.last_name}
              onChange={handleChange}
            />
            <div className="date_class">
              <label htmlFor="dob">Sünnikuupäev *</label>
              <select name="dobDay" value={date.dobDay} onChange={handleDate}>
                <option>dd</option>
                {Array.from(Array(31).keys()).map((i) => (
                  <option key={i} value={(i + 1).toString().padStart(2, 0)}>
                    {i + 1}
                  </option>
                ))}
              </select>
              <select
                name="dobMonth"
                value={date.dobMonth}
                onChange={handleDate}
              >
                <option>mm</option>
                {Array.from(Array(12).keys()).map((i) => (
                  <option key={i} value={(i + 1).toString().padStart(2, 0)}>
                    {i + 1}
                  </option>
                ))}
              </select>
              <select name="dobYear" value={date.dobYear} onChange={handleDate}>
                <option>yyyy</option>
                {Array.from(Array(100).keys()).map((i) => {
                  const year = new Date().getFullYear();
                  return (
                    <option key={i} value={year - i}>
                      {year - i}
                    </option>
                  );
                })}
              </select>
            </div>
            <div className="date_class">
              <label htmlFor="gender">Sugu</label>
              <select
                name="gender"
                value={profile.gender}
                onChange={handleChange}
              >
                <option value=""></option>
                <option value="mees">mees</option>
                <option value="naine">naine</option>
              </select>
            </div>
            <div className="date_class">
              <label htmlFor="insured">Kindlustatud</label>
              <select
                name="insured"
                value={profile.insured}
                onChange={handleChange}
              >
                <option value="True">jah</option>
                <option value="False">Ei</option>
              </select>
            </div>
            <label htmlFor="social_security">Isikukood *</label>
            <input
              className="line_input"
              type="text"
              name="social_security"
              value={profile.social_security}
              onChange={handleChange}
            />
            <label htmlFor="email">E-post *</label>
            <input
              className="line_input"
              type="email"
              name="email"
              value={profile.email}
              onChange={handleChange}
            />
            <label htmlFor="phone">Telefon</label>
            <input
              className="line_input"
              type="text"
              name="phone_number"
              value={profile.phone_number}
              onChange={handleChange}
            />
            <label htmlFor="phone">Aadress</label>
            <input
              className="line_input"
              type="text"
              name="address"
              value={profile.address}
              onChange={handleChange}
            />

            <label htmlFor="city">Linn</label>
            <input
              className="line_input"
              type="text"
              name="city"
              value={profile.city}
              onChange={handleChange}
            />

            <label htmlFor="language">Keel</label>
            <input
              className="line_input"
              type="text"
              name="language"
              value={profile.language}
              onChange={handleChange}
            />

            <label htmlFor="work_place">Töökoht</label>
            <input
              className="line_input"
              type="text"
              name="work_place"
              value={profile.work_place}
              onChange={handleChange}
            />

            <label htmlFor="work_position">Amet</label>
            <input
              className="line_input"
              type="text"
              name="work_position"
              value={profile.work_position}
              onChange={handleChange}
            />
            <label htmlFor="family_status">Perekonnaseis</label>
            <input
              className="line_input"
              type="text"
              name="family_status"
              value={profile.family_status}
              onChange={handleChange}
            />
            <label htmlFor="work_position">Religioon</label>
            <input
              className="line_input"
              type="text"
              name="religion"
              value={profile.religion}
              onChange={handleChange}
            />
          </form>
        </div>
      </div>
    </>
  );
};

export default Profile;

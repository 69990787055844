import React from "react";
import { useContext } from "react";

import { MainContext } from "../../common/MainContext";
import { useQueryClient } from "react-query";
import { useApiMutation } from "../../common/fetch/DataFetch";

const TreatmentPlanUpdate = ({ trPlanID }) => {
  const { trID, setError, error } = useContext(MainContext);
  const queryClient = useQueryClient();
  const updateTreatmentPlan = useApiMutation(
    "PUT",
    "/web/treatment/treatment-plan",
    {
      onSuccess: () => {
        queryClient.invalidateQueries(`treatmentPlan-${trID}`);
      },
      onError: (error) => {
        setError(error);
      },
    }
  );

  const handleTreatmentPlanUpdate = () => {
    const body = {
      treatment_id: trID,
      treatment_plan_id: trPlanID,
      valid: "false",
    };
    updateTreatmentPlan.mutate(body);
  };

  return (
    <>
      <button className="button-inverted" onClick={handleTreatmentPlanUpdate}>
        Lõpeta
      </button>
      <div className="notification_right">
        {error && <div className="error">{error.message}</div>}
      </div>
    </>
  );
};

export default TreatmentPlanUpdate;

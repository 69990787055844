import React from "react";

const ProfileDetail = ({ details, setDetails }) => {
  const handleChange = (e) => {
    let { name, value } = e.target;
    setDetails({
      ...details,
      [name]: value,
    });
  };
  return (
    <>
      <h4>Patsiendi terviseinfo</h4>
      <div className="main_form">
        <form>
          <label htmlFor="height">Pikkus (cm)</label>
          <input
            className="line_input"
            type="text"
            name="height"
            value={details.height}
            onChange={handleChange}
          />
          <label htmlFor="weight">Kaal (kg)</label>
          <input
            className="line_input"
            type="text"
            name="weight"
            value={details.weight}
            onChange={handleChange}
          />
          <label htmlFor="general_health">Üldine tervise kirjeldus</label>
          <textarea
            rows="2"
            maxLength="1000"
            type="text"
            className="line_input"
            value={details.general_health}
            name="general_health"
            onChange={handleChange}
          />
          <label htmlFor="allergies">Allergiad</label>
          <textarea
            rows="2"
            maxLength="1000"
            type="text"
            className="line_input"
            value={details.allergies}
            name="allergies"
            onChange={handleChange}
          />
          <label htmlFor="chronic_diseases">Kroonilised haigused</label>
          <textarea
            rows="2"
            maxLength="1000"
            type="text"
            className="line_input"
            value={details.chronic_diseases}
            name="chronic_diseases"
            onChange={handleChange}
          />
          <label htmlFor="medical_history">Põetud haigused</label>
          <textarea
            rows="2"
            maxLength="1000"
            type="text"
            className="line_input"
            value={details.medical_history}
            name="medical_history"
            onChange={handleChange}
          />

          <label htmlFor="medications">Ravimid</label>
          <textarea
            rows="2"
            maxLength="1000"
            type="text"
            className="line_input"
            value={details.medications}
            name="medications"
            onChange={handleChange}
          />
          <label htmlFor="family_history">Perekondlkud haigused</label>
          <textarea
            rows="2"
            maxLength="1000"
            type="text"
            className="line_input"
            value={details.family_history}
            name="family_history"
            onChange={handleChange}
          />
          <label htmlFor="healthy_habits">
            Sport ja tervislikud harjumused
          </label>
          <textarea
            rows="2"
            maxLength="1000"
            type="text"
            className="line_input"
            value={details.healthy_habits}
            name="healthy_habits"
            onChange={handleChange}
          />
          <label htmlFor="unhealthy_habits">
            Alkohol, suitsetamine, narkootikumid
          </label>
          <textarea
            rows="2"
            maxLength="1000"
            type="text"
            className="line_input"
            value={details.unhealthy_habits}
            name="unhealthy_habits"
            onChange={handleChange}
          />
          <label htmlFor="healthy_diet">Dieet</label>
          <textarea
            rows="2"
            maxLength="1000"
            type="text"
            className="line_input"
            value={details.healthy_diet}
            name="healthy_diet"
            onChange={handleChange}
          />

          {/* {localError && <div className="error">{localError}</div>}
            {success && <div className="success">{success}</div>} */}
        </form>
      </div>
    </>
  );
};

export default ProfileDetail;

import React from "react";
import { Link } from "react-router-dom";

const PatientQuestionaireMenu = () => {
  return (
    <>
      <p className="side_panel_heading">Küsimustikud</p>
      <div className="sidepanel_item">
        <span>
          {" "}
          <Link to="questionaire/eek/">
            Emotsionaalse enesetunde küsimustik
          </Link>
        </span>
      </div>
    </>
  );
};

export default PatientQuestionaireMenu;

import React from "react";
import { useEffect, useState } from "react";

import { useApi } from "../../common/fetch/DataFetch";
import { dateTimeDate } from "../../common/DateTime";

const ActivityLogs = () => {
  const [log, setLog] = useState([]);
  const url = `/web/manage/activity`;
  const key = `Logs`;
  const logs = useApi(key, url);

  useEffect(() => {
    if (logs.data) {
      setLog(logs.data.activity);
    }
  }, [logs]);

  return (
    <>
      <div className="main_form activity">
        <table>
          <thead>
            <tr>
              <th>User ID</th>
              <th>Activity ID</th>
              <th>Table</th>
              <th>Type</th>
              <th>Activity</th>
              <th>Comment</th>
              <th>Date added</th>
            </tr>
          </thead>
          <tbody>
            {log.map((log, key) => (
              <tr key={key}>
                <td>{log.user_id}</td>
                <td>{log.activity_id}</td>
                <td>{log.table_}</td>
                <td>{log.activity_type}</td>
                <td>{log.activity}</td>
                <td>{log.comment}</td>
                <td>{dateTimeDate(log.date_added)}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </>
  );
};

export default ActivityLogs;

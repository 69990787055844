import React, { useState, useContext } from "react";
import { useQueryClient } from "react-query";

import { MainContext } from "../../common/MainContext";

import { useApiMutation } from "../../common/fetch/DataFetch";

const TreatmentPlanAdd = () => {
  const { trID } = useContext(MainContext);
  const queryClient = useQueryClient();
  const [error, setError] = useState(null);
  const [formdata, setFormData] = useState({
    treatment: "",
    treatment_detail: "",
    treatment_comment: "",
    treatment_id: trID,
  });

  const handleChange = (e) => {
    setFormData({
      ...formdata,
      [e.target.name]: e.target.value,
    });
  };

  const addTreatmentPlan = useApiMutation(
    "POST",
    "/web/treatment/treatment-plan",
    {
      onSuccess: () => {
        queryClient.invalidateQueries(`treatmentPlan-${trID}`); // Invalidate treatment query
      },
      onError: () => {
        setError("Something went wrong...");
      },
    }
  );

  const handleSubmit = async (e) => {
    e.preventDefault();
    const form = e.currentTarget;
    form.disabled = true;
    try {
      if (formdata) {
        const response = await addTreatmentPlan.mutateAsync(formdata);
        if (response) {
          setFormData({
            treatment: "",
            treatment_detail: "",
            treatment_comment: "",
            treatment_id: trID,
          });
        }
      }
    } catch (error) {
      return <div className="error">Something went wrong...</div>;
    }

    form.disabled = false;
  };

  return (
    <div className="mobile_hide_wrapper">
      <form onSubmit={handleSubmit} className="small_form">
        <input
          placeholder="ravi"
          className="line_input"
          type="text"
          value={formdata.treatment}
          name="treatment"
          onChange={handleChange}
        />
        <input
          placeholder="raviplaan"
          className="line_input"
          type="text"
          value={formdata.treatment_detail}
          name="treatment_detail"
          onChange={handleChange}
        />
        <textarea
          rows="2"
          maxLength="1000"
          placeholder="raviplaani lisainfo"
          className="line_input"
          type="text"
          value={formdata.treatment_comment}
          name="treatment_comment"
          onChange={handleChange}
        />
        <div className="button_container_right">
          <button className="arrow_button" type="submit"></button>
        </div>
      </form>
      {error && <div className="error">Something went wrong...</div>}
    </div>
  );
};

export default TreatmentPlanAdd;

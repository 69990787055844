import React from "react";
import { useEffect, useState, useContext } from "react";

import { MainContext } from "../MainContext";
import { dateTimeDate } from "../DateTime";
import { useApi } from "../fetch/DataFetch";

import TreatmentPlanAdd from "../../modules/medic/TreatmentPlanAdd";
import TreatmentPlanUpdate from "../../modules/medic/TreatmentPlanUpdate";

const TreatmentPlan = () => {
  const { trID, treatmentData, role } = useContext(MainContext);
  const [treatmentplan, setTreatmentplan] = useState(null);
  const getUrl = `/web/treatment/treatment-plan/${trID}`;
  const getKeys = [`treatmentPlan-${trID}`];
  const response = useApi(getKeys, getUrl);

  useEffect(() => {
    if (response.data) {
      setTreatmentplan(treatmentData.treatment_plan);
    }
  }, [response, treatmentData]);

  if (response.isLoading) {
    return <div className="load_spinner"></div>;
  }

  if (response.isError) {
    return <div className="error">Something went wrong</div>;
  }
  if (treatmentplan) {
    return (
      <>
        {treatmentplan && (
          <>
            <h4>Raviplaan</h4>
            <div className="sidepanel_item">
              {treatmentplan.map((treatment, key) => {
                if (role === "medic" || treatment.valid) {
                  return (
                    <div key={key} className="treatment_plan_item">
                      <div
                        className={
                          treatment.valid ? "valid_text" : "invalid_text"
                        }
                      >
                        {treatment.treatment}
                      </div>
                      <div>{treatment.treatment_detail}</div>
                      <div>{treatment.treatment_comment} </div>
                      <div>{dateTimeDate(treatment.date_added)}</div>
                      {treatment.valid && role === "medic" ? (
                        <TreatmentPlanUpdate
                          trPlanID={treatment.treatment_plan_id}
                        />
                      ) : null}
                    </div>
                  );
                } else {
                  return null;
                }
              })}
              {role === "medic" && <TreatmentPlanAdd />}
            </div>
          </>
        )}
      </>
    );
  } else {
    return <div className="load_spinner"></div>;
  }
};

export default TreatmentPlan;

import React, { useContext } from "react";
import { MainContext } from "../../common/MainContext";
import { dateTimeDate } from "../../common/DateTime";

const Diagnosis = () => {
  const { treatmentData } = useContext(MainContext);
  return (
    <div className="patient_treatment">
      <div className="patient_treatment_date">
        {dateTimeDate(treatmentData.treatment_details.date_added)}
      </div>
      <div className="patient_treatment_medic">
        Lisas: {treatmentData.treatment_details.first_name}{" "}
        {treatmentData.treatment_details.last_name}
      </div>
      <div className="patient_treatment_diagnosis">
        {treatmentData.treatment_details.diagnosis}
        <br />
        {treatmentData.treatment_details.diagnosis.anamnesis}
      </div>
    </div>
  );
};

export default Diagnosis;

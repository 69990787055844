import React, { useState } from "react";
import { useApiMutation } from "../../common/fetch/DataFetch";
import { useQueryClient } from "react-query";

const Delete = ({ setSuccess, setError, userID, email }) => {
  const [confirm, setConfirm] = useState(null);
  const queryClient = useQueryClient();

  const DeleteMutation = useApiMutation("DELETE", "/web/manage/delete-user", {
    onSuccess: () => {
      setSuccess("kasutaja kustutatud");
      setTimeout(() => setSuccess(""), 3000);
    },
    onError: (error) => {
      setError("Something went wrong...");
      setTimeout(() => setError(""), 3000);
    },
  });

  const DeleteMutationEmail = useApiMutation(
    "DELETE",
    "/web/manage/delete-user-email",
    {
      onSuccess: () => {
        setSuccess("kasutaja kustutatud");
        setTimeout(() => setSuccess(""), 3000);
      },
      onError: (error) => {
        setError("Something went wrong...");
        setTimeout(() => setError(""), 3000);
      },
    }
  );

  const handleClickConfirm = () => {
    setConfirm(true);
  };

  const handleClickConfirmNull = () => {
    setConfirm(null);
  };
  const handleSubmit = async () => {
    try {
      if (userID !== null) {
        await DeleteMutation.mutateAsync({ user_id: userID });
        queryClient.invalidateQueries(`AdminList`);
        queryClient.invalidateQueries(`MedicList`);
      }
      if (email !== null) {
        await DeleteMutationEmail.mutateAsync({ email: email });
      }
      setSuccess("kasutaja kustutatud");
      setTimeout(() => setSuccess(""), 3000);
      setConfirm(null);
    } catch (error) {
      setError("Something went wrong...");
      setTimeout(() => setError(""), 3000);
    }
  };

  // Render the appropriate content based on the confirm state
  return (
    <div>
      {confirm === null ? (
        <button className="hide_button" onClick={handleClickConfirm} />
      ) : confirm === true ? (
        <>
          <button className="back_button" onClick={handleClickConfirmNull} />
          <button className="red_arrow_button" onClick={handleSubmit} />
        </>
      ) : null}
    </div>
  );
};

export default Delete;

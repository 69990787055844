import React, { useEffect, useState, useContext, useRef } from "react";

import { MainContext } from "../../common/MainContext";
import axios from "axios";

import config from "../../config_data/config.json";
import MedicSearchResults from "./MedicSearchResults";

const MedicSearch = () => {
  const { jwt } = useContext(MainContext);
  const [query, setQuery] = useState("");
  const [searchResults, setSearchResults] = useState([]);
  const [isError, setIsError] = useState(false);

  const debounce = (func, wait) => {
    let timeout;
    return function executedFunction(...args) {
      const later = () => {
        clearTimeout(timeout);
        func(...args);
      };
      clearTimeout(timeout);
      timeout = setTimeout(later, wait);
    };
  };

  const handleSearch = useRef(
    debounce((value) => {
      const url = `${config.apiBaseUrl}/web/search/medics/?keyword=${value}`;
      const fetchData = async () => {
        try {
          const response = await axios.get(url, {
            headers: {
              Authorization: `Bearer ${jwt}`,
            },
          });
          setSearchResults(response.data.medics);
        } catch (error) {
          setIsError(error.message);
        }
      };
      fetchData();
    }, 300)
  ).current;

  useEffect(() => {
    if (query) {
      handleSearch(query);
    } else {
      setSearchResults([]);
    }
  }, [query, handleSearch]);

  if (isError) {
    return <div className="error">Something went wrong ...</div>;
  }

  return (
    <>
      <input
        placeholder="Otsi nime või meiliaadressi järgi..."
        type="text"
        value={query}
        onChange={(e) => setQuery(e.target.value)}
      />
      <MedicSearchResults searchResults={searchResults} setQuery={setQuery} />
    </>
  );
};

export default MedicSearch;

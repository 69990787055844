import "./css/App.css";
import Login from "./common/auth/login";
import MedicHome from "./modules/medic/medicHome";
import PatientHome from "./modules/patient/patientHome";
import AdminHome from "./modules/admin/AdminHome";
import {
  MedicAccept,
  PatientAccept,
} from "./modules/invites/MedicAdminPatient";
import React from "react";
import { MainContext } from "./common/MainContext";
import { Route, Routes, Navigate } from "react-router-dom";
import Auth from "./common/auth/auth";

const roles = {
  patient: { path: "/patient/*", component: <PatientHome /> },
  medic: { path: "/medic/*", component: <MedicHome /> },
  admin: { path: "/admin/*", component: <AdminHome /> },
};

const App = () => {
  const { jwt } = React.useContext(MainContext);
  const { role } = React.useContext(MainContext);
  let path = null;
  let component = null;

  if (jwt && role && roles[role]) {
    path = roles[role].path;
    component = roles[role].component;
  }
  return (
    <div className="App">
      <React.Fragment>
        <Auth />

        <Routes>
          <Route path="/medic/accept/:inviteID" element={<MedicAccept />} />
          <Route path="/patient/accept/:inviteID" element={<PatientAccept />} />
          {path && component ? (
            <React.Fragment>
              <Route exact path={path} element={component} />
              <Route path="*" element={<Navigate to={path} replace />} />
            </React.Fragment>
          ) : (
            <React.Fragment>
              <Route exact path="/login" element={<Login />} />
              <Route path="*" element={<Navigate to="/login" replace />} />
            </React.Fragment>
          )}
        </Routes>
      </React.Fragment>
    </div>
  );
};

export default App;

import { useQuery, useMutation, useQueryClient } from "react-query";
import axios from "axios";

import baseUrl from "../../config_data/config.json";

const axiosInstance = axios.create({
  baseURL: baseUrl.apiBaseUrl,
  headers: {
    Accept: "application/json",
    "Content-Type": "application/json",
  },
});

const useApi = (key, url, withJwt = true) => {
  return useQuery(key, async () => {
    const jwt = withJwt ? localStorage.getItem("token") : null;
    const authHeader = jwt ? { Authorization: "Bearer " + jwt } : {};
    const response = await axiosInstance.get(url, { headers: authHeader });
    return response.data;
  });
};

const useApiMutation = (
  method,
  url,
  options = {},
  useFormData = false,
  withJwt = true
) => {
  const queryClient = useQueryClient();
  const { onSuccess = () => {}, onError = () => {} } = options;
  return useMutation(
    async (data) => {
      const jwt = withJwt ? localStorage.getItem("token") : null;
      const authHeader = jwt ? { Authorization: "Bearer " + jwt } : {};

      const headers = useFormData
        ? { "Content-Type": "application/x-www-form-urlencoded", ...authHeader }
        : { "Content-Type": "application/json", ...authHeader };

      const body = useFormData ? data : JSON.stringify(data);

      const response = await axiosInstance({
        method,
        url,
        data: body,
        headers,
      });
      return response.data;
    },
    {
      onError: (error) => {
        console.error("Error in useApiMutation: ", error);
        onError(error);
      },
      onSuccess: (data) => {
        onSuccess(data);
        queryClient.invalidateQueries();
      },
    }
  );
};

export { useApi, useApiMutation };

import { useEffect } from "react";
import { useApiMutation } from "../fetch/DataFetch";
import { MainContext } from "../MainContext";
import { useContext } from "react";

const useRefreshToken = () => {
  const { jwt, setJwt } = useContext(MainContext);
  const refreshTokenMutation = useApiMutation("GET", "/web/user/token-update");

  useEffect(() => {
    if (!jwt) {
      return;
    }

    const refreshToken = async () => {
      try {
        const response = await refreshTokenMutation.mutateAsync();
        setJwt(response.access_token);
      } catch (error) {
        if (error.response && error.response.status !== 200) {
          setJwt(null);
          localStorage.clear();
        }
      }
    };

    const intervalId = setInterval(refreshToken, 60000); // Refresh every 60000 milliseconds (1 minute)

    return () => {
      clearInterval(intervalId);
    };
  }, [jwt, setJwt, refreshTokenMutation]);

  // This useEffect hook will run only once on initial loading to check if the token is valid

  useEffect(() => {
    const checkTokenValidity = async () => {
      if (!jwt) {
        setJwt(null);
        localStorage.clear();
      }

      try {
        await refreshTokenMutation.mutateAsync();
      } catch (error) {
        if (error.response && error.response.status !== 200) {
          setJwt(null);
          localStorage.clear();
        }
      }
    };

    checkTokenValidity();
    // as I need this to run only once on initial loading, I have disabled the exhaustive-deps rule
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []); // An empty dependency array indicates that this hook should only run once on initial loading

  return null;
};

export default useRefreshToken;
